@use "sass:color";

$toolbar-width: 56px;
$navigation-width: 56px;
$nav-shadow: 0 0 0 rgb(40 41 61 / 4%),
  2px 3px 4px rgb(96 97 112 / 16%);

.kip-whiteboard {
  position: relative;
  height: 100%;
  user-select: none;

  &__pdf-buttons {
    position: absolute;
    z-index: 99;
    background: $white;
    max-height: 100%;
    box-shadow: $nav-shadow;
    border-bottom-right-radius: 0.35em;

    .kip-scroller {
      cursor: pointer;
      background: $white;
      line-height: 56px;
      min-width: 2rem;
      display: none;
      height: 56px;
      width: 56px;
      min-height: 56px;

      &:hover {
        background: $kip-blue-100;
      }

      &:last-child {
        border-bottom-right-radius: 0.35em;
      }
    }

    .kip-items {
      list-style: none;
      overflow: hidden;
      position: relative;
      padding: 0;

      &--rounded {
        padding-bottom: 5px;
        border-bottom-right-radius: 0.35em;

        li:nth-last-child(2) {
          border-bottom-right-radius: 0.35em;
        }
      }

      .kip-anchor {
        display: block;
      }

      .kip-item {
        :hover {
          background: $kip-blue-100;
        }

        .kip-item-inner {
          cursor: pointer;
          border: 0 solid;
          line-height: 28px;
          min-width: 2rem;
          height: 56px;
          width: 56px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include question-navigation;
        }
      }
    }

    &.kip-scrollable {
      .kip-scroller {
        display: block;
      }

      .kip-items {
        margin: 0;
      }
    }
  }

  &__sound-player {
    position: absolute;
    bottom: 0;
    left: calc(50% - 215px);
    z-index: 21;
  }

  .kip-canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      pointer-events: none;
    }
  }

  .kip-whiteboard-toolbar {
    position: sticky;
    top: 0;
    pointer-events: none;

    &-container {
      width: 3.5em;
      overflow: hidden;
      animation: toolbar-pop-in 0.5s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      pointer-events: all;
      border-top-left-radius: 8px;
      box-shadow: $box-shadow-btn;
      margin-bottom: -1em;
      padding-bottom: 1em;

      // max-height: 70%;

      &.closing {
        transition: all ease-in-out 0.5s;
        padding-top: 1000px;
        opacity: 0;
      }

      .text-primary {
        svg {
          fill: $primary;
        }
      }
    }

    @keyframes toolbar-pop-in {
      from {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        overflow: hidden;
        box-shadow: none;
      }

      to {
        max-height: 1000px;
        opacity: 1;
        margin-bottom: 0;
        padding-bottom: 0;
        overflow: hidden;
        box-shadow: none;
      }
    }

    .kip-item {
      display: flex;
      align-items: center;
      height: 44px;

      svg {
        height: 24px;
        width: 24px;
        fill: $primary;

        [stroke="black"] {
          stroke: $primary;
          stroke-width: 2px;
        }

        @include theme {
          fill: theme-get("primary");

          [stroke="black"] {
            stroke: theme-get("primary");
          }
        }
      }
    }

    .kip-tool-item {
      position: relative;

      .kip-options {
        background-color: $white;
        position: absolute;
        left: -4.75em;

        .kip-option {
          margin: 0 1px;

          &:hover {
            background-color: $gray-400;
          }

          &.kip-selected {
            color: $white;
            background-color: $primary;
          }
        }
      }
    }
  }
}

@media screen {
  body {
    &>div.window-printing-container {
      display: none;
    }
  }
}

@media print {
  body {
    position: relative !important; // Override reboot.css

    &.kip-whiteboard-printing {
      overflow: visible;
      background-color: $white;
    }

    &.kip-whiteboard-printing>kip-root {
      display: none;
    }

    &>div.window-printing-container {
      display: block;
    }

    &>div.window-printing-container>img {
      position: absolute;
    }
  }
}

.kip-whiteboard-printing {
  &__page-wrapper,
  &__pdf {
    position: relative;
    width: 100%;
  }

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
  }
}

.kip-whiteboard-container {
  position: absolute;
  inset: 0;
  z-index: 10;
  height: 100%;
  width: 100%;

  .kip-canvas {
    overflow: auto;
    overflow-x: hidden;
  }

  .kip-whiteboard-toolbar {
    position: absolute;
    bottom: 1.25em;
  }

  &--scrolling-mode {
    .canvas-container {
      pointer-events: none;
      cursor: grab;
    }
  }

  .kip-whiteboard-close {
    color: $danger;
    background-color: $white;

    &:active,
    &.active {
      border-color: $primary;
      z-index: 20;
      background: $kip-red-100;
    }

    &:hover {
      background: $kip-red-200;
      cursor: pointer;
    }
  }

  // Add margin around manual PDF content
  
  &--manual {
    .kip-canvas {
      img {
        padding: 0 5%;
      }
    }
  }
}
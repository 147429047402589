@use "sass:math";
@use "sass:color";

$horizontal-spacer: (
  math.div($spacer, 2)
);
$vertical-spacer: (
  math.div($spacer, 2)
);
$grow-factor: 1;
$grow-factor--small: (
  math.div($grow-factor, 2)
);
$grow-factor--wide: (
  $grow-factor * 2
);
$grow-factor--extra-wide: (
  $grow-factor--wide * 3
);
$activity-detail__max-width: 150px;
$activity-detail__max-width--small: 100px;

@mixin base-history-list-item() {
  overflow: hidden;
  position: relative;
}

@mixin make-lesson-result-indicator($indicator-color) {
  background-color: $indicator-color;
  border-bottom: 1px solid color.adjust($indicator-color, $lightness: -10%);
  margin-bottom: -1px; // @TODO: (HACK) Overlay Container Border with Indicator's Colour
}

.history-list {
  border-bottom: 1px solid $border-color;
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  width: 100%;

  &__activity-title {
    @include base-history-list-item;

    flex: $grow-factor--extra-wide 1 0;
    margin: $vertical-spacer 0;
    padding: 0 $horizontal-spacer;

    @include media-breakpoint-up(lg) {
      flex: $grow-factor--wide 0 0;
    }
  }

  &__activity-detail {
    @include base-history-list-item;

    flex: $grow-factor--wide 0 0;
    margin: $vertical-spacer 0;
    padding: 0 $horizontal-spacer;

    @include media-breakpoint-between(lg, xl) {
      flex: $grow-factor 1 0;
    }

    @include media-breakpoint-up(xl) {
      max-width: $activity-detail__max-width;
    }

    &--small {
      flex: $grow-factor 1 0;
      margin: $vertical-spacer 0;

      @include media-breakpoint-between(lg, xl) {
        flex: $grow-factor--small 1 0;
      }

      @include media-breakpoint-up(xl) {
        max-width: $activity-detail__max-width--small;
      }
    }
  }

  &__result-indicator {
    @include base-history-list-item;

    flex: 0 0 auto;
    width: $horizontal-spacer;

    &--success {
      @include make-lesson-result-indicator($kip-lesson-color--success);
    }

    &--warning {
      @include make-lesson-result-indicator($kip-lesson-color--warning);
    }

    &--danger {
      @include make-lesson-result-indicator($kip-lesson-color--danger);
    }
  }
}

@use "sass:color";

.kip-video {
  &--tcc {
    background-color: #f9f9f9;

    /* stylelint-disable no-descending-specificity */
    // Rule disabled as to match and override vendor CSS
    .OT_video-poster,
    .OT_fit-mode-cover .OT_video-poster {
      background-color: #f9f9f9;
      background-image: url("data:image/svg+xml,%3Csvg width='175' height='200' viewBox='0 0 175 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' d='M87.5 100C114.844 100 137.5 77.7344 137.5 50C137.5 22.6562 114.844 0 87.5 0C59.7656 0 37.5 22.6562 37.5 50C37.5 77.7344 59.7656 100 87.5 100ZM122.266 112.5H115.625C107.031 116.797 97.6562 118.75 87.5 118.75C77.3438 118.75 67.5781 116.797 58.9844 112.5H52.3438C23.4375 112.5 0 136.328 0 165.234V181.25C0 191.797 8.20312 200 18.75 200H156.25C166.406 200 175 191.797 175 181.25V165.234C175 136.328 151.172 112.5 122.266 112.5Z' fill='black'/%3E%3C/svg%3E%0A");
      opacity: 1;
      background-size: auto 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .OT_root .OT_video-loading {
      background-color: #f9f9f9;

      .OT_video-loading-spinner {
        background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5 2.54255C8.79151 2.54255 2.54255 8.79151 2.54255 16.5C2.54255 24.2085 8.79151 30.4574 16.5 30.4574V32.5C7.66344 32.5 0.5 25.3366 0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5H30.4574C30.4574 8.79151 24.2085 2.54255 16.5 2.54255Z' fill='black' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &--lobby,
  &--lesson {
    background-color: $secondary;
    background-image: url("data:image/svg+xml,%3Csvg width='175' height='200' viewBox='0 0 175 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M87.5 100C114.844 100 137.5 77.7344 137.5 50C137.5 22.6562 114.844 0 87.5 0C59.7656 0 37.5 22.6562 37.5 50C37.5 77.7344 59.7656 100 87.5 100ZM122.266 112.5H115.625C107.031 116.797 97.6562 118.75 87.5 118.75C77.3438 118.75 67.5781 116.797 58.9844 112.5H52.3438C23.4375 112.5 0 136.328 0 165.234V181.25C0 191.797 8.20312 200 18.75 200H156.25C166.406 200 175 191.797 175 181.25V165.234C175 136.328 151.172 112.5 122.266 112.5Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center center;
    mask-image: radial-gradient(white, black); // Hack to hide overflow from border-radius in Safari

    .OT_video-poster,
    .OT_fit-mode-cover .OT_video-poster {
      background-color: $secondary;
      background-image: url("data:image/svg+xml,%3Csvg width='175' height='200' viewBox='0 0 175 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M87.5 100C114.844 100 137.5 77.7344 137.5 50C137.5 22.6562 114.844 0 87.5 0C59.7656 0 37.5 22.6562 37.5 50C37.5 77.7344 59.7656 100 87.5 100ZM122.266 112.5H115.625C107.031 116.797 97.6562 118.75 87.5 118.75C77.3438 118.75 67.5781 116.797 58.9844 112.5H52.3438C23.4375 112.5 0 136.328 0 165.234V181.25C0 191.797 8.20312 200 18.75 200H156.25C166.406 200 175 191.797 175 181.25V165.234C175 136.328 151.172 112.5 122.266 112.5Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
      opacity: 1;
      background-size: auto 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    /* stylelint-enable no-descending-specificity */

    .OT_root .OT_video-loading {
      background-color: $secondary;
    }
  }

  &--lobby {
    background-image: url("data:image/svg+xml,%3Csvg width='147' height='124' viewBox='0 0 147 124' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M142.359 105.878L84.1172 6.34668C81.6562 2.51855 77.8281 0.604492 74 0.604492C69.8984 0.604492 66.0703 2.51855 63.6094 6.34668L5.09375 105.878C0.992188 113.534 6.46094 123.104 15.4844 123.104H132.242C141.266 123.104 146.734 113.534 142.359 105.878ZM18.2188 109.979L73.7266 15.0967L129.508 109.979H18.2188ZM74 84.2764C69.0781 84.2764 65.25 88.1045 65.25 92.7529C65.25 97.4014 69.0781 101.229 74 101.229C78.6484 101.229 82.4766 97.4014 82.4766 92.7529C82.4766 88.1045 78.6484 84.2764 74 84.2764ZM67.4375 42.167V68.417C67.4375 72.2451 70.1719 74.9795 74 74.9795C77.5547 74.9795 80.5625 72.2451 80.5625 68.417V42.167C80.5625 38.6123 77.5547 35.6045 74 35.6045C70.1719 35.6045 67.4375 38.6123 67.4375 42.167Z' fill='white'/%3E%3C/svg%3E");
    background-size: auto 25%;
  }

  &--denied {
    background-color: $secondary;
    background-image: url("data:image/svg+xml,%3Csvg width='93' height='94' viewBox='0 0 93 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M46.5 0.5C20.8125 0.5 0 21.3125 0 47C0 72.6875 20.8125 93.5 46.5 93.5C72.1875 93.5 93 72.6875 93 47C93 21.3125 72.1875 0.5 46.5 0.5ZM61.5 32C64.6875 32 67.5 34.8125 67.5 38C67.5 41.375 64.6875 44 61.5 44C58.125 44 55.5 41.375 55.5 38C55.5 34.8125 58.125 32 61.5 32ZM31.5 32C34.6875 32 37.5 34.8125 37.5 38C37.5 41.375 34.6875 44 31.5 44C28.125 44 25.5 41.375 25.5 38C25.5 34.8125 28.125 32 31.5 32ZM63.375 73.0625C59.0625 68 52.875 65 46.5 65C39.9375 65 33.75 68 29.4375 73.0625C27 76.0625 22.3125 72.125 24.9375 69.125C30.1875 62.75 38.0625 59 46.5 59C54.75 59 62.625 62.75 67.875 69.125C70.5 72.125 65.8125 76.0625 63.375 73.0625Z' fill='white'/%3E%3C/svg%3E%0A");
    background-size: auto 50%;
  }

  &--blur video {
    filter: blur(4px);
  }

  &__overlay {
    z-index: 2;
    background-color: rgb(0 0 0 / 50%);
    line-height: 1.2;
  }

  &__student {
    min-height: 4rem;
    height: 14rem;
  }

  &__tutor {
    min-height: 7rem;
    height: 14rem;
    border-bottom: 0.125em solid $white;
  }

  &__tutor-microphone {
    bottom: 0.75em;
    right: 0.75em;
  }

  &__tutor-name {
    position: absolute;
    width: auto;
    background: $kip-blue-100;
    padding: 0 8px;
    border-radius: 8px;
    color: $primary;
    z-index: 2;

    @include theme {
      color: theme-get("primary");
      background: theme-get("primary-200");
    }
  }

  &__button {
    color: $primary;
    background: $white;
    height: 2.5em;
    width: 2.5em;
    border-radius: 0.5em;
    transition: color 0.3s, background-color 0.3s;
    cursor: pointer;

    @include theme {
      color: theme-get("primary");

      &:hover {
        color: theme-get("primary");
        background-color: theme-get("primary-100");
      }

      &:active,
      &:focus {
        border-color: theme-get("primary-300");
      }
    }

    &:hover,
    &--hover {
      color: $primary;
      background-color: color.adjust($primary-medium, $lightness: 46.5%);
    }

    &:active,
    &:focus {
      border: 0.2em solid color.adjust($primary-medium, $lightness: 25%);
    }

    &--active {
      background-color: color.adjust($primary-medium, $lightness: 50%);
      transition: color 0.3s, background-color 0.3s;

      @include theme {
        background: theme-get("primary-200");
      }
    }

    &--highlighted {
      cursor: pointer;
      color: $success;
      background-color: $kip-green-100;
      transition: color 0.3s, background-color 0.3s;

      @include theme {
        color: $success;
      }
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      background: $white;
    }

    &--pulse {
      background-color: $electric-storm-primary-200;
      animation: animate-pulse-background-electric-storm 1.5s infinite;
      animation-direction: alternate;
    }

    @keyframes animate-pulse-background-electric-storm {
      from {
        background-color: $white;
      }

      to {
        background-color: $electric-storm-primary-200;
      }
    }
  }
}

// This removes the black line that appears on the right when the video is flipped via OT_Mirror.
// Also note that all of the above classes are part of the old video component.
.tutor-video-border {
  // max-width: 49.99%;
}

// @HACK for tutor sidebar student card list, keeping video elements visible so we can move them to
// the teach card even when switching to another tab (chat, event log).

$video-position-top: -210px;
$video-position-left: 9px;
$pixels-off-screen: 10000px;

.tutor-tabs .tab-pane:first-of-type,
.kip-tutor-sidebar .nav-tabs .tab-pane:first-of-type {
  position: relative;

  .promotedVideo {
    position: absolute;
    top: $video-position-top;
    left: $video-position-left;

    &.isDropIn {
      top: calc(#{$video-position-top} - 48px); // Account for lesson available bar in Drop In sessions
    }

    &.isAssessment {
      top: calc(#{$video-position-top} + 21px); // Account for lesson card differences in Assessment sessions
    }
  }

  &:not(.active) {
    display: block !important; // Unhide tabs, is usually display: none
    position: absolute;
    left: -$pixels-off-screen; // Hide first tabsheet off the screen
    opacity: 1;

    .promotedVideo {
      left: calc(#{$pixels-off-screen} + #{$video-position-left}); // Move promoted video back to a visible area
    }
  }
}

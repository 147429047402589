@use "sass:math";

$tcc-nav-height: 3rem;

.kip-tutor-sidebar {
  z-index: 1000;
  width: $sidebar-width;
  min-width: $sidebar-width;

  $button-size: 44px;

  .kip-color-picker-body {
    transform: scale(0.9);
    transform-origin: left;
  }

  @include media-breakpoint-up(lg) {
    width: $sidebar-width-lg;
    min-width: $sidebar-width-lg;

    .kip-color-picker-body {
      transform: scale(1);
    }
  }

  @include media-breakpoint-up(xl) {
    width: $sidebar-width-xl;
    min-width: $sidebar-width-xl;
  }

  .kip-sidebar-card {
    &:first-of-type {
      margin-top: math.div($spacer, 2);
    }

    &__column {
      width: calc(50% - #{$button-size});
    }

    &__button {
      width: $button-size;
    }
  }

  select.form-control {
    padding: 1em;
  }

  ul[role="tablist"] {
    height: $tcc-nav-height;

    fa-icon {
      font-size: 1.25em;
    }
  }

  div[role="tabpanel"] {
    height: 100%;
  }

  .tab-content {
    position: relative;
    height: 100%;
    max-height: calc(100% - #{$tcc-nav-height});
  }

  .nav-link {
    color: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 0;
    border-bottom: thin solid transparent;
    transition: color 0.3s;

    &:hover {
      color: $primary;
      border: 0;
      border-bottom: thin solid $primary;
    }

    &.active {
      color: $primary;
      background-color: $white;
      border: 0;
      border-bottom: thin solid $primary;
    }
  }

  &__strengths {
    height: 33%;
    min-height: 8em;
  }
}

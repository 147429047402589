@use "sass:color";

$navigation-offset: 10em;
$sidebar-width: 15em;
$circle-diameter: 70px;
$circle-border-width: 4px;
$circle-background-color: linear-gradient(315deg, rgb(154 234 230 / 20%) 0%, rgb(100 175 241 / 20%) 43.75%, rgb(146 115 230 / 20%) 100%), #fff;
$circle-border-color-remaining: #ccc;
$circle-border-color-elapsed: $primary;
$circle-inner-border-width: 4px;
$circle-colors: (
  success: ("primary": #077861,
    "primary-400": color.mix($kip-green-200, $white, $weight: 70%),
    "primary-100": $kip-green-100 ),
  warning: ("primary": color.mix($kip-orange-500, $white, $weight: 70%),
    "primary-400": $kip-orange-100,
    "primary-100": $kip-orange-100 ),
  danger: ("primary": $kip-red-400,
    "primary-400": color.mix($kip-red-200, $white, $weight: 70%),
    "primary-100": $kip-red-100 ),
  secondary: ("primary": $secondary,
    "primary-400": color.mix($gray-400, $white, $weight: 70%),
    "primary-100": $gray-100 ),
);

@mixin make-hover-transition() {
  transition: background-color 0.2s ease-in;
}

@mixin make-kip-button-hover($color) {
  @include make-hover-transition;

  background-color: color.adjust($color, $lightness: -7%);
}

.kip-lesson {
  .h-100 {
    min-height: 0;
  }

  $lesson-toolbar-height: 34px;

  .kip-lesson-header {
    font-size: 2.168rem;
  }

  .kip-lesson-navbar {
    .kip-logo-container {
      margin-top: 2px;
    }
  }

  .kip-assessment {
    &__welcome {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.2;
        background-image: url("../../assets/assessment.jpg");
        background-size: cover;
        background-position: 50% 50%;
      }
    }
  }

  .kip-activity-summary {
    @include margin-column-gutters;

    align-items: center;
    display: flex;
    box-shadow: 0 0 1px rgb(40 41 61 / 8%), 0 0.5px 2px rgb(96 97 112 / 16%);

    &__title {
      @include media-breakpoint-up(md) {
        &--manual {
          width: calc(100% - 400px);
        }

        &--computer {
          width: calc(100% - 80px);
        }
      }
    }

    &--complete {
      user-select: none;
      pointer-events: none;
    }

    &--active {
      background: color.adjust($primary, $lightness: 65%);
      border-color: color.adjust($primary, $lightness: -5%);
      color: $primary;

      @include theme {
        background-color: theme-get("primary-200");
      }
    }

    &:hover {
      @include make-hover-transition;

      background-color: $kip-blue-100;
      cursor: pointer;

      @include theme {
        background-color: theme-get("primary-100");
      }
    }

    th,
    td {
      border: 0;
    }

    &:first-child {
      border-bottom: 1px solid $card-border-color;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $card-border-color;
    }
  }

  .kip-activity {
    position: relative;

    &__title {
      // This should probably be moved out, since it is across all screens now
      height: 4em;
      background-color: $white;

      &--lesson {
        height: 4em;
        position: absolute;
        z-index: 101;
        top: -($spacer * 3);
        left: 0;
        margin-top: -1em;
        width: 100%;
      }
    }

    .card-body {
      mask-image: radial-gradient(white, black); // Hack to hide overflow from border-radius in Safari
      border-radius: 20px;
    }

    &__close {
      font-weight: 500;
    }

    &__button {
      height: 56px;
    }

    &__activity-number {
      font-size: $font-size-lg;
      width: 2.5em;
      white-space: nowrap;
    }

    &__circle {
      color: $primary;
      width: $circle-diameter;
      height: $circle-diameter;
      display: block;
      border-radius: 50%;
      position: relative;
      border: none;

      @include theme {
        color: theme-get("primary");
        background-color: theme-get("primary");
      }

      &::after {
        display: block;
        position: absolute;
        top: $circle-border-width;
        left: $circle-border-width;
        height: $circle-diameter - 2 * $circle-border-width;
        width: $circle-diameter - 2 * $circle-border-width;
        line-height: $circle-diameter - 2 * $circle-border-width - 2 * $circle-inner-border-width;
        background: $circle-background-color;
        border-radius: 50%;
        border: $circle-inner-border-width solid $white;
        font-size: 1.4em;
        text-align: center;

        @include theme {
          background: theme-get("primary-100");
        }
      }

      @for $j from 0 through 50 {
        &.p#{$j} {
          @include theme {
            /* stylelint-disable function-linear-gradient-no-nonstandard-direction */
            background-image: linear-gradient(90deg + (360 * 0.01 * $j), transparent 50%, theme-get("primary-400") 50%), linear-gradient(90deg, theme-get("primary-400") 50%, transparent 50%);
            /* stylelint-enable function-linear-gradient-no-nonstandard-direction */
          }

          &::after {
            content: "";
          }
        }


        @each $color,
        $value in $circle-colors {

          &--#{$color}.p#{$j} {
            @include theme {
              /* stylelint-disable function-linear-gradient-no-nonstandard-direction */
              background-image: linear-gradient(90deg + (360 * 0.01 * $j), transparent 50%, map-deep-get($circle-colors, #{$color}, "primary-400") 50%),
              linear-gradient(90deg, map-deep-get($circle-colors, #{$color}, "primary-400") 50%, transparent 50%);
              /* stylelint-enable function-linear-gradient-no-nonstandard-direction */
              color: map-deep-get($circle-colors, #{$color}, "primary");
              background-color: map-deep-get($circle-colors, #{$color}, "primary");
            }

            &::after {
              content: "";
              background-color: map-deep-get($circle-colors, #{$color}, "primary-100");
            }
          }
        }
      }

      @for $j from 51 through 100 {
        &.p#{$j} {
          @include theme {
            background-image: linear-gradient(90deg, transparent 50%, theme-get("primary") 50%), linear-gradient(-90deg + (360 * 0.01 * ($j - 50)), transparent 50%, theme-get("primary-400") 50%);
          }

          &::after {
            content: "";
          }
        }

        @each $color,
        $value in $circle-colors {

          &--#{$color}.p#{$j} {
            @include theme {
              background-image: linear-gradient(90deg, transparent 50%, map-deep-get($circle-colors, #{$color}, "primary") 50%),
              linear-gradient(-90deg + (360 * 0.01 * ($j - 50)), transparent 50%, map-deep-get($circle-colors, #{$color}, "primary-400") 50%);
              color: map-deep-get($circle-colors, #{$color}, "primary");
              background-color: map-deep-get($circle-colors, #{$color}, "primary");
            }

            &::after {
              content: "";
              background-color: map-deep-get($circle-colors, #{$color}, "primary-100");
            }
          }
        }
      }

      &--start {
        background: linear-gradient(315deg, rgb(154 234 230 / 50%) 0%, rgb(100 175 241 / 50%) 43.75%, rgb(146 115 230 / 50%) 100%), #fff; // Aurora
        background-size: 100% 100%;

        @include theme {
          background: theme-get("gradient-50");
        }

        &::after {
          content: "";
        }
      }
    }

    &__result {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .kip-questions-content {
      position: relative;
      overflow-y: auto;
      
      &--computer {
        .kip-question {
          margin-left: $toolbar-width;
          width: calc(100% - #{$toolbar-width} * 2);
        }
      }

      .kip-result {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 30;

        img {
          width: 150px;
        }

        .kip-correct {
          color: $success;
          background: transparent;
          margin: 2rem;
        }

        .kip-incorrect {
          color: $danger;
          background: transparent;
          margin: 2rem;
        }
      }
    }

    .kip-completed {
      position: absolute;
      inset: 0;
      z-index: 40;

      .kip-overlay {
        background-color: $gray-900;
        position: absolute;
        inset: 0;
        opacity: 0.4;
      }

      .kip-image {
        position: absolute;
        inset: 0;

        img {
          border: 1px solid $gray-600;
          width: 40%;
        }
      }
    }
  }

  .kip-question-controls {
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      height: 100%;
      margin-top: -100%;
      position: absolute;
    }

    label {
      pointer-events: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.5em;
      height: 3.5em;
      font-size: 1em;
      box-shadow: $box-shadow-btn;

      svg {
        fill: $primary;

        [fill="black"] {
          fill: $primary;
        }

        @include theme {
          fill: theme-get("primary");

          [fill="black"] {
            fill: theme-get("primary");
          }
        }
      }
    }
  }
}

.kip-lesson-sidebar {
  position: relative;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  background-color: $white;
  color: $secondary;
  border-radius: $border-radius--large * 2;

  &__video-controls {
    user-select: none;
    min-height: 3em;
  }

  &--activity-list {
    @media (width >=999px) {
      height: calc(100vh - $navigation-offset); // fallback
      height: calc((var(--vh, 1vh) * 100) - $navigation-offset);
      position: sticky;
      top: 0;
    }
  }

  .kip-video:first-of-type {
    border-top-left-radius: $border-radius--large * 2;
    border-top-right-radius: $border-radius--large * 2;
    overflow: hidden;
    mask-image: radial-gradient(white, black); // Hack to hide overflow from border-radius in Safari
  }
}

.kip-fill-in-the-blank-layout {
  .kip-container {
    position: relative;
    user-select: none !important;

    .kip-source-container {
      background-color: $aurora-primary-100;

      @include theme {
        background-color: theme-get("primary-100");
      }

      border-radius: 0.5em;
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-self: center;
      flex-wrap: wrap;

      .kip-source {
        margin: 0 0.1em;
        color: $primary;

        @include theme {
          color: theme-get("primary");
        }
          
        cursor: pointer;

        &.kip-item-selected {
          background-color: $green;
        }

        &:hover {
          color: $green;
        }
      }

      .kip-source-item {
        color: $orange;
      }
    }

    .kip-target-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      answer {
        @extend .kip-bring-to-front;

        display: inline-block;

        .kip-target {
          white-space: nowrap !important;
          padding: 0 0.25rem;
          border-bottom: 2px solid $primary;

          @include theme {
            border-bottom: 2px solid theme-get("primary");
          }
    
        }

        .kip-target-item {
          margin-bottom: 1px;
          margin-top: 1px;
        }

        .kip-placeholder {
          background-color: $gray-300;
          margin-top: 1px;
          margin-bottom: 1px;
          width: 50px;
        }

        .kip-placeholder-tutor {
          margin-top: 1px;
          margin-bottom: 1px;
          width: auto;
          padding: 0 0.25rem;
          border-radius: 4px;
        }
      }
    }

    .kip-mirror-item {
      position: absolute;
      background-color: $aurora-primary-100;

      @include theme {
        background-color: theme-get("primary-100");
      }

      opacity: 0.8;
      border-radius: 0.5em;
      padding: 0 0.25rem;
    }

    .kip-spacer {
      height: 50px;
    }
  }
}

.kip-analogue-clock-layout {
  .kip-outer {
    stroke: #000;
    stroke-width: 2;
    fill: transparent;
  }

  .kip-center {
    fill: #000;
  }

  .kip-marker {
    stroke: #000;
    stroke-width: 1;

    &.kip-major {
      stroke-width: 2;
    }
  }

  .kip-number {
    font-size: 1.1rem;
    stroke: #000;
    stroke-width: 1;
    text-anchor: middle;
  }

  .kip-hour-hand {
    stroke: #000;
    stroke-width: 8;
  }

  .kip-hour-hand-valid {
    stroke: $success;
    stroke-width: 8;
  }

  .kip-hour-hand-invalid {
    stroke: $danger;
    stroke-width: 8;
  }

  .kip-minute-hand {
    stroke: #000;
    stroke-width: 4;
  }

  .kip-minute-hand-valid {
    stroke: $success;
    stroke-width: 4;
  }

  .kip-minute-hand-invalid {
    stroke: $danger;
    stroke-width: 4;
  }

  .kip-handle {
    stroke: transparent;
    fill: transparent;

    &:hover {
      @media (hover: none) {
        fill: rgba($kip-blue-400, 0.2);
      }
    }
  }
}

$tab-height: 75px;
$tab-offset: 20px;

.kip-student {
  .kip-tabs {
    height: $tab-height;
    margin-bottom: -$tab-offset;
    background: map-deep-get($themes, "aurora", "gradient-25");

    @include theme {
      background: theme-get("gradient-25");
    }

    &>div {
      height: 90px;
      background: none;

      &.active {
        background: #fff;
      }
    }
  }

  .kip-tabs-container {
    padding: 1px;

    @include theme {
      background: theme-get("gradient-25");
    }

    .kip-tabs {
      min-height: $tab-height;

      @include theme {
        background: unset;
      }
    }
  }

  .kip-tabs-content {
    min-height: 17em;
    max-height: 25em;
    height: 42vh;
  }
}

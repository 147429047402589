@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Note - all utilities should be marked as !important

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius--small !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius--small !important;
}

.rounded-bottom-left--xl {
  border-bottom-left-radius: $border-radius--large * 2 !important;
}

.rounded-right--xl {
  border-top-right-radius: $border-radius--large * 2 !important;
  border-bottom-right-radius: $border-radius--large * 2 !important;
}

.rounded-top-left--xl {
  border-top-left-radius: $border-radius--large * 2 !important;
}

.rounded-top-left {
  border-top-left-radius: 0.35em !important;
}

.rounded-100 {
  border-radius: 100% !important;
}

.rounded-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-top-lg {
  border-top-left-radius: $border-radius--large * 2 !important;
  border-top-right-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-right-lg {
  border-bottom-right-radius: $border-radius--large * 2 !important;
  border-top-right-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-left-lg {
  border-bottom-left-radius: $border-radius--large * 2 !important;
  border-top-left-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-lg {
  border-bottom-left-radius: $border-radius--large * 2 !important;
  border-bottom-right-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-top-left-lg {
  border-top-left-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-bottom-right-lg {
  border-bottom-right-radius: $border-radius--large * 2 !important;
  overflow: hidden;
}

.rounded-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}

.shadow-lg {
  box-shadow: $box-shadow-lg !important;

  @include theme {
    /* stylelint-disable color-function-notation */
    box-shadow: 2px 4px 4px rgba(theme-get("primary"), 0.2) !important;
    /* stylelint-enable color-function-notation */
  }
}

.shadow-btn {
  box-shadow: $box-shadow-btn !important;
}

.shadow-tight {
  box-shadow: 0 4px 4px rgb(212 212 212 / 50%) !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-grab {
  cursor: grab !important;

  &:active {
    cursor: grabbing !important;
  }
}

.stroke-white {
  stroke: $white;
  stroke-width: 20;
}

.stroke-dark {
  stroke: $dark;
  stroke-width: 20;
}

.stroke-none {
  stroke-width: 0;
}

.flex-even {
  flex: 1;
}

.w-33 {
  width: 33% !important;
}

.w-66 {
  width: 66% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.h-1 {
  height: 1px !important;
}

.h-btn {
  height: $button-height !important;
  display: flex;
  align-items: center;
}

.mh-50vh {
  max-height: 50vh !important;
}

.position-unset {
  position: unset !important;
}

.top-0 {
  position: absolute;
  top: 0 !important;
}

.bottom-0 {
  position: absolute;
  bottom: 0 !important;
}

.left-0 {
  position: absolute;
  left: 0 !important;
}

.right-0 {
  position: absolute;
  right: 0 !important;
}

.z-index-20 {
  position: relative;
  z-index: 20;
}

.border-dashed {
  border-style: dashed !important;
}

.border-primary {
  transition: border-color 0.15s;
  border-color: $primary !important;

  @include theme {
    border-color: theme-get("primary-300") !important;
  }
}

.border-success {
  transition: border-color 0.15s;
  border-color: $kip-green-200 !important;

}

.border-warning {
  transition: border-color 0.15s;
  border-color: $kip-orange-200 !important;
}

.border-danger {
  transition: border-color 0.15s;
  border-color: $kip-red-200 !important;
}

.border-secondary {
  transition: border-color 0.15s;
  border-color: $gray-200 !important;
}

.overflow-y {
  overflow: auto !important;
  overflow-x: hidden !important;
}

.overflow-x {
  overflow: auto !important;
  overflow-y: hidden !important;
}

.btn-height {
  height: $button-height;
}

@keyframes animate-pulse-background {
  from {
    background-color: $white;
  }

  to {
    background-color: #ecf5ff;
  }
}

.text-primary {
  @include theme {
    color: theme-get("primary") !important;
  }
}

.text-primary-400 {
  color: $aurora-primary-400 !important;

  @include theme {
    color: theme-get("primary-400") !important;
  }
}

.text-light {
  color: $gray-400 !important;
}

.text-gold {
  color: gold !important;
}

.truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fs-07 {
  font-size: 0.7rem;
}


.bg-primary-gradient {
  background: map-deep-get($themes, "aurora", "gradient-bg");
  background-size: 100% 100%;

  @include theme {
    background: theme-get("gradient-bg");
  }
}

.bg-gradient-solid {
  background: map-deep-get($themes, "aurora", "gradient-solid");
  background-size: 100% 100%;

  @include theme {
    background: theme-get("gradient-solid");
  }
}

.bg-gradient-50 {
  background: map-deep-get($themes, "aurora", "gradient-50");
  background-size: 100% 100%;

  @include theme {
    background: theme-get("gradient-50");
  }
}

.bg-pulse {
  background-color: $danger;
  animation: animate-pulse-background 1.5s infinite;
  animation-direction: alternate;
}

.bg-none {
  background: none !important;
}

.bg-green-200 {
  background: $kip-green-200 !important;
}

.bg-orange-50 {
  background-color: $kip-orange-50 !important;
}

.bg-red-100 {
  background-color: $kip-red-100 !important;
}

.bg-red-200 {
  background: $kip-red-200 !important;
}

@include media-breakpoint-up(md) {
  .rounded-md-lg {
    border-radius: $border-radius--large * 2 !important;
  }

  .bg-md-primary-100 {
    background-color: $kip-blue-100;
  }

  .bg-md-aurora-primary-50 {
    background-color: $aurora-primary-50;
  }

  .bg-md-aurora-primary-100 {
    background-color: $aurora-primary-100;
  }

  .bg-md-aurora-primary-200 {
    background-color: $aurora-primary-200;
  }
}

@include media-breakpoint-down(md) {
  .bg-sm-kip-white {
    background-color: $kip-white;
  }
}

.bg-primary-100 {
  background-color: $kip-blue-100 !important;

  @include theme {
    background-color: theme-get("primary-100") !important;
   }
}

.bg-primary-50 {
  background-color: $aurora-primary-50 !important;

  @include theme {
    background-color: theme-get("primary-50") !important;
   }
}


.bg-aurora-primary-50 {
  background-color: $aurora-primary-50 !important;
}

.bg-aurora-primary-100 {
  background-color: $aurora-primary-100 !important;
}

.bg-aurora-primary-200 {
  background-color: $aurora-primary-200 !important;
}

.overflow-initial {
  overflow: initial !important;
}

.fade-in-out {
  animation: fadeInOut ease-in-out 2s;
  animation-fill-mode: alternate;
  animation-iteration-count: 3;
}

.fade-in {
  animation: fadeIn ease-in 0.25s;
  animation-fill-mode: forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@use "sass:math";
@use "sass:color";

$form-label__color: $body-color;
$form-border-color: #ced4da;

.form-control-invalid {
  display: none;
}

.form-group-invalid {
  .form-control-invalid {
    align-items: center;
    display: flex;
    color: $danger;
    font-size: $root__type--small;
    padding-top: 2px;
  }

  .form-control {
    border-color: color.adjust($danger, $lightness: 10%);

    &:focus {
      box-shadow: $box-shadow-danger;
    }
  }
}

.kip-form-group {
  color: $secondary;
  margin-bottom: 1rem;

  & label {
    color: $form-label__color;
    font-size: $root__type--small;
    margin-bottom: 0.5rem;
  }
}

legend {
  color: $form-label__color;
  font-size: $h2-font-size;
  font-weight: $font-weight-light;

  &.legend--small {
    font-size: $root__type--small;
    font-weight: inherit;
  }
}

.kip-choice:not(.form-switch) {
  align-items: center;
  border: 1px solid $form-border-color;
  border-radius: $border-radius;
  color: #505d68;
  display: flex;
  padding: $spacer * 0.75;
  transition: all 0.15s ease-in;
  user-select: none;
  width: 100%;
  cursor: pointer;

  label {
    margin-bottom: 0;
  }

  @include theme {
    border: 1px solid transparent;
    box-shadow: $box-shadow-sm;
    padding: 0;

    label {
      font-size: 1em;
      color: $secondary;
      cursor: pointer;
    }

    @include make-custom-checkbox;

    &:hover {
      border-color: theme-get("primary");
    }

    &:focus-within {
      outline: theme-get("primary") auto 5px;
    }
  }

  &:focus-within {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:hover {
    border: 1px solid #3889d4;
    box-shadow: 0 0 8px rgb(56 137 212 / 20%);
    text-decoration: none;
  }

  // @TODO: HACK: Fake the [checked] by filling the label to of container - padding.
  &>* {
    width: 100%;
  }

  &:not(:last-of-type) {
    margin-bottom: math.div($spacer, 2);
  }
}

.form-check-label {
  &--append {
    padding-left: 0.25rem;
    width: 100%;
  }
}

.form-check {
  min-height: unset;
  margin-bottom: 0;
}

.kip-button-group {
  display: flex;
  flex-wrap: wrap;

  :only-child {
    border-radius: $border-radius--small;
  }

  :first-child {
    border-bottom-left-radius: $border-radius--small;
    border-top-left-radius: $border-radius--small;
  }

  :last-child {
    border-bottom-right-radius: $border-radius--small;
    border-top-right-radius: $border-radius--small;
  }

  &__option {
    --bs-btn-active-border-color: #3889d4;
    --bs-btn-active-bg: #{$kip-blue-40};
    --bs-btn-active-color: #{$primary};

    align-items: center;
    background: #fff;
    border: 1px solid $form-border-color;
    box-sizing: border-box;
    color: $body-color;
    display: flex;
    font-weight: 600;
    font-size: $root__type--small;
    min-height: 3rem;
    justify-content: center;
    letter-spacing: 0.1rem;
    line-height: 1rem;
    text-align: center;

    &:hover {
      background: #f5faff;
      border: 1px solid #3889d4;
      color: $primary;
      cursor: pointer;
    }

    @include theme {
      --bs-btn-box-shadow: #{$box-shadow-sm};
      --bs-btn-focus-box-shadow: #{$box-shadow-sm};
      --bs-btn-active-bg: #{theme-get("primary-100")};
      --bs-btn-active-color: #{theme-get("primary")};

      font-weight: 500;
      border: 0;
      box-shadow: $box-shadow-sm;
      color: theme-get("primary");

      &:hover {
        border: 0;
        background: theme-get("primary-50");
      }
    }
  }

  &__radio {
    @extend .kip-button-group__option;

    color: $raven;
    line-height: 1.5rem;
    min-height: auto;
    letter-spacing: unset;
    text-transform: uppercase;

    @include theme {
      text-transform: unset;
      padding: 1em 0;
      letter-spacing: 1.25px;
      font-size: 1em;

      // Style theme selector buttons in settings

      &[for="theme_theme-aurora"] {
        color: $aurora-primary;
        background: $white;
      }

      &[for="theme_theme-electric-storm"] {
        color: $electric-storm-primary;
        background: $white;
      }

      &[for="theme_theme-lab-green"] {
        color: $lab-green-primary;
        background: $white;
      }

      &[for="theme_theme-sunset"] {
        color: $sunset-primary;
        background: $white;
      }
    }


    // Hover states
    --bs-btn-bg: #{$kip-blue-100};
    --bs-btn-color: #{$primary};
    --bs-btn-border-color: #{$primary};
  }

  .disabled {
    pointer-events: none;
  }

  .active,
  input:checked+label {
    background: $kip-blue-100;
    border: 1px solid $primary;
    color: $primary;
    box-shadow: 0 0 0 0.2rem #ccddec;

    &[for="theme_theme-aurora"] {
      color: $white;
      background: $aurora-gradient;
    }

    &[for="theme_theme-electric-storm"] {
      color: $white;
      background: $electric-storm-gradient;
    }

    &[for="theme_theme-lab-green"] {
      color: $white;
      background: $lab-green-gradient;
    }

    &[for="theme_theme-sunset"] {
      color: $white;
      background: $sunset-gradient;
    }

    @include theme {
      border: 0;
    }
  }
}

select {
  &.form-control {
    height: auto;
    color: $dark;
    appearance: none;
    font-size: inherit;
    background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23424242' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 1em 0.875em;
    padding-right: 2.5em;

    &:focus {
      color: $dark;
    }
  }
}

@use "sass:math";

.dropdown-toggle-split {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px  !important;
}

.dropdown-toggle:not(.dropdown-toggle-split),
.dropup .dropdown-toggle:not(.dropdown-toggle-split) {
  cursor: pointer;

  &::after {
    // Remove caret from dropdown
    display: none;
  }
}

.show-caret::after {
  display: inline-block;
}

// Fix dropdown position for mobile, override Bootstrap
.dropdown-menu[data-bs-popper] {
  left: auto;
}

.kip-dropdown {
  user-select: none;

  &__menu {
    min-width: fit-content !important;
    position: absolute !important; // Override possible inline style
    filter: drop-shadow(0 2px 8px rgb(40 41 61 / 4%)) drop-shadow(0 16px 24px rgb(96 97 112 / 16%));
    font-size: 0.89rem;
    margin-top: math.div($spacer, 2);
    margin-right: -(math.div($spacer, 2));
    padding: 0;
    left: auto;
    right: auto;
    max-width: 21rem;
    border: 0;
    border-radius: 10px;
    z-index: 110;

    kip-color-picker {
      display: inline-block;
      margin: 0.25rem 0;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border: 10px solid $black;
      border-color: transparent transparent $white $white;
      transform-origin: 0 0;
      box-shadow: -2px 2px 3px rgb(0 0 0 / 10%);
    }

    &--no-arrow {
      &::after {
        display: none;
      }
    }

    &--top-left {
      margin-left: -$spacer;

      &::after {
        transform: rotate(135deg);
        top: 0;
        left: $spacer * 3;
      }

      &-sm {
        &::after {
          left: $spacer * 1.25 + 0.1 !important; // Magic number a bit
          border-width: 6.5px;
          top: 1px;
        }
      }

      &[data-popper-placement="bottom-end"] {
        // If position flips to top-right because of lack of space
        &::after {
          display: none;
        }
      }
    }

    &--top-right {
      right: 0;
      margin-left: $spacer + 0.175;

      &::after {
        transform: rotate(135deg);
        top: 0;
        right: -(math.div($spacer, 2));

        @include media-breakpoint-up(sm) {
          right: -$spacer * 0.55;
        }
      }
    }

    &--bottom-left {
      &::after {
        transform: rotate(-45deg);
        bottom: -$spacer * 1.25;
        left: $spacer * 1.25;
      }
    }

    &--bottom-right {
      &::after {
        transform: rotate(-45deg);
        bottom: -$spacer * 1.25;
        right: $spacer * 1.5;
      }
    }

    &--right-top {
      right: 0;
      margin-left: $spacer + 0.175;

      &::after {
        transform: rotate(45deg);
        top: 0.25em;
        left: 0;

        @include media-breakpoint-up(sm) {
          right: -$spacer * 0.55;
        }
      }
    }

    &--margin-left-175 {
      margin-left: -1.75rem !important; // @TODO: Fix !important
    }
  }

  &__entry {
    --bs-btn-hover-border-color: #{$border-color};
    --bs-btn-border-radius: 0;
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #{$kip-blue-100};
    --bs-btn-active-border-color: #{$border-color};

    display: flex;
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.5rem 0.8rem 0.5rem 0.5rem;
    border-bottom: 1px solid $border-color;
    width: 100%;
    align-items: center;
    white-space: nowrap;
    transition: background-color 0.15s;
    cursor: pointer;

    &--group {
      border-bottom: 0;
    }

    @include theme {
      font-size: 1rem;
    }

    &:hover {
      color: $primary;
      background: $kip-blue-100;

      @include theme {
        color: theme-get("primary");
        background-color: theme-get("primary-100");
      }
    }

    &:active {
      background-color: #e3f5fc;
      color: $primary;

      @include theme {
        background-color: theme-get("primary");
        color: $white;
      }
    }

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      border-bottom: 0;
    }

    input {
      margin: 0 !important;
    }
  }

  &__toggle {
    min-width: 20em;
    width: 100%;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 0.5rem;

    &:hover {
      background: $kip-blue-50;
    }
  }

  &__overflow {
    max-height: 50vh;
    overflow-y: auto;

    &+.kip-dropdown__toggle {
      border-top: 1px solid $border-color;
    }
  }

  &__open--activity {
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;

    &.show {
      background: $kip-blue-50;

      &::before {
        // content: "";
        position: absolute;
        width: 0;
        height: 0;
        box-sizing: border-box;
        border: 9px solid $black;
        border-color: transparent transparent $white $white;
        transform-origin: 0 0;
        box-shadow: -2px 2px 3px rgb(0 0 0 / 10%);
        transform: rotate(135deg);
        bottom: -1.75em;
        left: $spacer * 3;
        z-index: 120;
      }
    }
  }

  @mixin make-theme-swatch {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 3px;
    left: 3px;
    border-radius: 50%;
  }

  &__theme-swatch {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
    transition: all 0.5s;
    margin-right: 1px;

    &:last-of-type {
      margin-right: 0;
    }

    &::after {
      @include make-theme-swatch;
    }

    &:hover,
    &.selected {
      cursor: pointer;
      position: relative;

      &::after {
        @include make-theme-swatch;
      }
    }

    @each $theme,
    $key in $themes {
      &--#{$theme} {
        &::after {
          background: map-deep-get($themes, #{$theme}, "gradient-solid");
        }

        &:hover,
        &.selected {
          background: map-deep-get($themes, #{$theme}, "gradient-50");

          &::after {
            background: map-deep-get($themes, #{$theme}, "gradient-solid");
          }
        }
      }
    }
  }
}

@use "sass:color";

@media print {
  body {
    &.modal-printing {
      overflow: visible !important;
      position: unset !important;

      &>.modal {
        position: absolute !important;
        overflow: visible !important;

        &>div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          max-width: 100%;
          min-height: 100%;
          background-color: #fff;
          margin: 0;
          display: block !important;

          &>div {
            border: none !important;

            & button {
              display: none !important;
            }

            & .modal-body {
              height: auto !important;
            }
          }
        }
      }
    }
  }
}

.kip-modal-themed,
.theme-aurora {
  .modal-content {
    border-radius: 20px;
    border: 0;
  }
}



.modal-backdrop {
  $convertToRgb: rgba($raven, 0.6);

  --bs-backdrop-bg: #{$convertToRgb};

  backdrop-filter: blur(5px);
}


.modal {
  transition: 0.15s ease-out transform;

  &.fade:not(.show) {
    transform: scale(0.5);
  }

  &.fade .modal-dialog {
      transform: translate(0, 0);
  }
  
  &.fade .modal-content {
    animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  
  50% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }}

}

.kip-drill-layout {
  margin: auto;
  width: fit-content;

  .drill-total-line-bottom {
    border-top: solid;
    border-top-width: 2px;
    border-top-color: black;
  }

  .drill-total-line-top {
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: black;
  }

  .drill-left {
    border-left: solid;
    border-left-width: 2px;
    border-left-color: black;
  }

  .drill-answer {
    width: 20px;
  }
}

@use "sass:color";

$nav-shadow: 0 0 0 rgb(40 41 61 / 4%), 2px 3px 4px rgb(96 97 112 / 16%);
$last-item-shadow: 0 4px 2px rgb(40 41 61 / 4%), 2px 0 4px rgb(96 97 112 / 16%);

.kip-question-navigator {
  font-family: Rubik, sans-serif;
  font-size: 16px;

  .kip-scroller {
    cursor: pointer;
    background: $white;
    line-height: 56px;
    min-width: 2rem;
    display: none;
    height: 56px;
    width: 56px;
    min-height: 56px;
    box-shadow: $nav-shadow;

    @media (hover: hover) {
      &:hover {
        background: $kip-blue-100;
      }
    }
  }

  .kip-items {
    list-style: none;
    overflow: hidden;
    position: relative;
    padding: 0;
    box-shadow: $nav-shadow;
    
    &--rounded {
      border-bottom-right-radius: 0.35em;
    }

    .kip-anchor {
      display: block;
    }

    .kip-item {
      .kip-item-inner {
        cursor: pointer;
        border: 0 solid;
        line-height: 28px;
        min-width: 2rem;
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include question-navigation;
      }

      &.kip-active {
        .kip-item-inner {
          &.kip-attempts-0 {
            border-color: $primary;
            background-color: color.adjust($primary, $lightness: 60%);
            color: $primary;

            @include theme {
              transition: background-position 0.1s;
              background: theme-get("gradient-looped");
              background-size: 200% auto;
              color: $white;

              @media (hover: hover) {
                &:hover {
                  color: $white !important; // @TODO: Need to separate kip-attempts from the alert mixin
                  background: theme-get("gradient-looped");
                  background-position: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  &.kip-scrollable {
    .kip-scroller {
      display: block;
    }

    .kip-items {
      margin: 0;
    }
  }
}

@use "sass:math";
@use "sass:map";

$height__fixed-bottom: 3rem;
$monty-height: 22em;

.kip-tile {
  $inner-spacer: (math.div($spacer, 2));
  $spacer__inner--vertical: (math.div($spacer, 3));
  $spacer__inner--horizontal: ($spacer);

  &__shell {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 2.75rem;
    grid-template-rows: auto-fit 1fr;
    grid-template-areas: "summary menu";
  }

  &__shell--attempted {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 2.75rem;
    grid-template-rows: auto-fit 1fr;
    grid-template-areas:
      "summary menu"
      "stats menu";
  }

  &__learning-health {
    align-items: center;
    display: flex;
    grid-area: result;
    justify-content: center;
  }

  &__summary {
    display: flex;
    grid-area: summary;
    min-width: 0;
    overflow: hidden;
    padding: math.div($spacer, 4);

    .flex-child {
      &--override-auto-width {
        flex: 1;
        min-width: 0;
      }

      &__trailing-wrapper--nowrap {
        white-space: nowrap;

        &>fa-icon {
          display: inline-block;
        }
      }
    }

    .truncate {
      &--ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__description {
    align-items: center;
    display: flex;
    grid-area: description;
    font-weight: $font-weight-light;
    min-width: 0;
    font-size: 12px;

    &--attempted {
      padding: 0;
    }
  }

  &__stats {
    border-right: 1px solid $card-border-color;
    align-items: center;
    display: flex;
    grid-area: stats;
    justify-content: space-between;
    font-weight: 700;

    &:hover {
      border-right: 1px solid $card-border-color;
    }
  }

  &__more-actions {
    grid-area: menu;

    .show .kip-actions-toggle {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0.25em;
        left: 3em;
        width: 0;
        height: 0;
        box-sizing: border-box;
        border: 10px solid $black;
        border-color: transparent transparent $white $white;
        transform-origin: 0 0;
        box-shadow: -2px 2px 3px rgb(0 0 0 / 10%);
        transform: rotate(45deg);
      }
    }
  }

  &--full-width {
    grid-column: 1/3;
  }
}

.kip-lesson-plan {
  .btn-plan-accepted {
    color: $success;
    border-color: $kip-green-400;
    background-color: $kip-green-200;

    .kip-icon {
      color: $success;
    }

    &--warning {
      color: $warning;
      border-color: $kip-orange-400;
      background-color: $kip-orange-100;
    }
  }

  .kip-btn:hover {
    border-color: $border-color;
  }

  &__monty {
    padding: 1.5em;
    text-align: center;
    height: $monty-height;

    &--buttons {
      height: calc(#{$monty-height} - 3em);
    }
  }

  .kip-drag-highlight {
    .kip-columns {
      box-shadow: inset 0 0 0 0.2rem rgb(0 84 159 / 25%);
    }

    .kip-lesson-plan__monty {
      display: none;
    }
  }

  .kip-lesson-plan-summary {
    background-color: #fff;

    .kip-columns {
      overflow: auto;
      overflow-x: hidden;
  
      &__split-view-separator {
        border-right: 0;

        @include media-breakpoint-only(xl) {
          border-right: 1px solid $gray-300;
        }

        @include media-breakpoint-between(sm, md) {
          border-right: 1px solid $gray-300;
        }
      }

      .kip-column-header {
        background-color: $gray-100;
      }

      .kip-activity {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }

      &.kip-drag-container {
        &:last-child {
          margin-bottom: 10em;
        }
      }

      .kip-actions-toggle {
        color: $gray-700;
        font-size: 1.4rem;
        min-height: $button-height;

        &::after {
          display: none;
        }
      }

      .kip-manual {
        background-color: $gray-200;
      }

      .kip-history {
        .kip-vertical-text {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          font-size: 0.7rem;
        }
      }
    }
  }

  &__content {
    flex: 1;
    overflow: hidden;
  }

  &__activities {
    max-width: 61em;
  }

  &__wrapper {
    display: flex !important;
    flex-direction: column !important;

    @include media-breakpoint-up(xl) {
      min-width: 20.5rem;
    }
  }

  &__single &__footer {
    max-height: $height__fixed-bottom;
  }

  &--scrollable {
    overflow: auto;
  }

  .kip-summary__lesson-indicator {
    font-weight: 700;
    width: 44px;
    text-align: center;

    &--success {
      color: $success;
      background: $kip-green-200;
    }

    &--warning {
      color: $warning;
      background: $kip-orange-200;
    }

    &--danger {
      color: $danger;
      background: $kip-red-200;
    }
  }
}

.kip-student-chip {
  margin-bottom: 0.5em;
  background-color: $white;
  color: $danger;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 0.94rem;
  padding: 0;
  overflow: hidden;
  position: relative;
  border: 1px solid $gray-400;
  flex-grow: 1;
  max-width: 350px;

  &__status {
    background: $kip-red-100;
  }

  &>.kip-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
  }

  &.kip-planned {
    &:not(.kip-student-chip--active) {
      color: $success;

      &:hover {
        color: $success;
        border-color: $kip-green-400;
      }


      .kip-icon {
        color: $success;
      }
    }

    .kip-student-chip__status {
      background: $kip-green-100;
    }

    &:hover:not(.kip-student-chip--active) {
      background-color: $kip-green-50;
    }
  }

  &.kip-unplanned {
    &:not(.kip-student-chip--active) {
      &:hover {
        color: $danger;
        border-color: $danger;
      }

      .kip-icon {
        color: $danger;
      }
    }

    &:hover:not(.kip-student-chip--active) {
      background-color: $kip-red-50;
    }
  }

  &--active {
    color: $primary;
    background-color: $kip-blue-50;
    border-color: $primary;
    outline: 1px solid $primary !important;
    margin-left: -1px;
    margin-right: -1px;

    &:hover {
      border-color: $primary;
      outline: 1px solid $primary !important;
      background-color: $kip-blue-100;
    }
  }

  &.kip-absent {
    cursor: default;
    opacity: 0.5;

    .kip-avatar {
      background-color: $gray-600;
    }
  }
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Firefox */
}

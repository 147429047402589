@use "sass:color";

@mixin make-overline-style($color: $primary) {
  color: $color;
  font-size: 0.79rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 0.889rem;
  text-transform: uppercase;
}

.text-overline {
  @include make-overline-style($color: $primary);
}

.text-overline--inherit {
  @include make-overline-style($color: inherit);
}

.text-overline--secondary {
  @include make-overline-style($color: $secondary);
}

@mixin make-definition-style {
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.0025em;
  line-height: 1.125rem;
  color: $secondary;
}

@mixin make-body-alternate-style {
  font-style: normal;
  font-weight: normal;
  font-size: 0.79rem;
  letter-spacing: 0.0025em;
  line-height: 0.875rem;
}

// Type utilities

.small-2 {
  font-size: 0.875rem !important;
  line-height: 1.2;
}

.small-3 {
  font-size: 0.6875rem;
}

.small-4 {
  font-size: 0.5rem;
}

.small-5 {
  font-size: 0.79rem;
}

.small-6 {
  font-size: 0.845rem !important;
}

.text-tabular-nums {
  font-variant-numeric: tabular-nums !important;
}

.text-clamp {
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-sans-serif {
  font-family: sans-serif;
}

.text-btn {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.25px;
}

.font-size-normal {
  font-size: 1em !important;
}

.font-italic {
  font-style: italic !important;
}

.ls-normal {
  letter-spacing: normal !important;
}

.ls-wide {
  letter-spacing: 0.05em !important;
}

@mixin make-text-link($color: $primary) {
  cursor: pointer;
  color: $color;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.054em;
  transition: color 0.15s;

  &:hover {
    color: color.adjust($color, $lightness: -25%);
  }
}

.text-link {
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.054em;
}

.text-link-primary {
  @include make-text-link($primary);
}

.font-spacing-wide {
  letter-spacing: 1.5px !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-gold {
  color: gold !important;
}
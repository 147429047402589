@use "sass:color";

@mixin stepper-hover($step-colour) {
  &:hover {
    background-color: color.adjust($step-colour, $lightness: -6%);
    border-color: color.adjust($step-colour, $lightness: -10%);
    box-shadow: 0 4px 4px rgba($step-colour, 0.1);
  }
}

@mixin stepper-button-modifier($step-colour) {
  background-color: $step-colour;
  border-color: $step-colour;
  color: #fff;

  @include stepper-hover($step-colour);
}

.kip-wizard {
  $step-border: 2px solid #cfcfcf;

  display: flex;
  list-style: none;
  justify-content: space-between;

  &__button {
    $button-class: #{&};
    $button-size: 1.5rem;

    align-items: center;
    background-color: transparent;
    border: $step-border;
    border-radius: 50%;
    display: flex;
    height: $button-size;
    justify-content: center;
    line-height: 1rem;
    padding: 0;
    width: $button-size;

    @include stepper-hover(#E8E8E8);

    &--selected {
      @include stepper-button-modifier(#5AB0E2);
    }

    &--valid {
      @include stepper-button-modifier(#45AD64);
    }

    &--error {
      @include stepper-button-modifier(#A03535);
    }

    &--warning {
      @include stepper-button-modifier(#97690D);
    }
  }

  $step-wrapper: #{&}__step-container;

  #{$step-wrapper} {
    display: flex;
    width: 100%;
    align-items: center;

    &::before,
    &::after {
      content: "";
      flex: 1;
      margin: auto 0;
      height: 0;
      border-top: $step-border;
    }
  }

  li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-of-type #{$step-wrapper}:before {
      border: 0;
    }

    &:last-of-type #{$step-wrapper}:after {
      border: 0;
    }

    label {
      cursor: pointer;
    }
  }
}

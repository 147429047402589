kip-custom-avatar-base svg {
  width: 100%;
}

.kip-avatar-pick {
  border: 2px solid transparent;
  display: inline-block;
  cursor: pointer;

  svg {
    width: 100%;
  }
}

.kip-avatar-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 3.5em;
  height: 3.5em;
  border-radius: 8px;
  margin: 0 0.5em 0.5em 0;
  box-shadow: $box-shadow-btn;
  cursor: pointer;

  svg [fill] {
    fill: $primary;

    @include theme {
      fill: theme-get("primary");
    }
  }

  &.active {
    background-color: $aurora-primary-200;

    @include theme {
      background-color: theme-get("primary-200");
    }
  }
}

.kip-avatar-selected,
.kip-avatar-selected-color {
  background-color: $aurora-primary-200;

  @include theme {
    background-color: theme-get("primary-200");
  }
}

.kip-avatar-pick-color {
  width: 39px;
  height: 39px;
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  margin: 0 0.25em 0.25em 0;
}

.kip-avatar-textarea {
  width: 240px;
  height: 200px;
}

.kip-spelling-layout {
  .kip-letters {
    height: 2.5rem;

    .kip-input {
      width: 5rem;

      &.form-control[readonly] {
        background-color: $white !important;
      }
    }

    @include kip-input-width;
  }
}

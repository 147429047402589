.kip-custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;

  &:hover,
  &.focused {
    background-color: #e6e6e6;
  }
}

.kip-has-lesson {
  background-color: #f0ad4e;
  border-radius: 1rem;
  color: white;
}

.kip-scoring-session-header {
  background-color: #f9f9f9;
}

.kip-scoring-lesson-header {
  background-color: #ecf5ff;
}

.modal-lg {
  max-width: 618px;
}

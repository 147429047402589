@import "../variables";

.kip-success-request-body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .kip-success-request-body-icon {
    height: 96px;
    width: 96px;

    path {
      fill: $kip-green-400;
    }
  }

  .success-title {
    margin: 16px 0;
    font-weight: 500;
    font-size: 28.833px;
    letter-spacing: -0.5px;
  }

  .description-text {
    font-weight: 400;
    font-size: 20.25px;
    letter-spacing: 0.25px;
  }

  .text-style {
    font-style: normal;
    text-align: center;
    color: $raven;
  }

  .success-request-close-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.054em;
    color: $kip-blue-500;
  }
}

.kip-multiple-choice-layout {
  .custom-control.custom-radio {
    white-space: nowrap;
  }

  .kip-option-text {
    white-space: nowrap;

    p {
      margin-bottom: 0; // Remove margin from options made with paragraphs
    }

    img {
      min-width: 15vh;
    }
  }

  .kip-option {
    .kip-label {
      &::before,
      &::after {
        margin-top: 0.75rem;
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .valid-choice {
      color: #155724 !important;
      background-color: #d4edda;
    }

    .invalid-choice {
      color: #a03535 !important;
      background-color: #fae4d8;
    }

    @include media-breakpoint-down(sm) {
      .kip-label {
        &::before,
        &::after {
          margin-top: 0.25rem;
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  76% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.kip-digital-clock-layout {
  .kip-columns {
    background-color: black;

    .kip-button {
      color: $white;
    }

    .kip-digits {
      cursor: default;
      color: rgb(124 252 0);
      font-family: Digital-Regular, sans-serif;
      font-size: 4.5rem;
      user-select: none;
    }

    .kip-digits-valid {
      background-color: unset !important;
    }

    .kip-digits-invalid {
      background-color: unset !important;
    }

    .kip-blink {
      animation: blink 1s infinite;
    }
  }
}

@use "sass:math";

.kip-badge {
  @extend .badge;

  border-radius: 4px;

  padding: {
    left: (math.div($spacer, 3));
    right: (math.div($spacer, 3));
  }

  ;

  text-transform: uppercase;

  &--primary {
    @include make-overline-style;

    background-color: #ecf5ff;

    @include theme {
      background: theme-get("primary-50");
      color: theme-get("primary");
    }
  }

  &--outline {
    @include make-overline-style;

    color: $gray-800;
    border: 1px solid $gray-700;
  }

  &--round {
    border-radius: 1em;
  }

  &--small {
    font-size: 0.6875rem;
    line-height: normal;
    padding: 0 0.25rem;
  }
}

@use "sass:math";

.kip-tab {
  $gutter: 0.5rem;
  $row-gap: 1rem;
  $third: math.div(100%, 3);
  $half: math.div(100%, 2);

  margin: $spacer;

  &--is-edit {
    display: flex;
    margin: $spacer;
  }

  &__header {
    font-weight: $font-weight-light;
    margin-bottom: $spacer;
  }

  &__article-list {
    display: block;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: calc(#{$gutter} * -1);
    }
  }

  &__article {
    margin: 0 0 $row-gap;
    width: 100%;

    @include media-breakpoint-up(lg) {
      @include make-detail-column($half);

      padding: $gutter;
      margin: 0;
    }

    @include media-breakpoint-up(xxl) {
      @include make-detail-column($third);

      padding: $gutter;
    }
  }
}

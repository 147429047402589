.kip-session-list {
  &__session {
    border-radius: 0.625rem;
    padding: 1rem;
    transition:
      border 0.1s ease-in,
      box-shadow 0.1s ease-in;

    &--selected {
      border-color: #3889d4;
    }

    &:focus-within {
      @include bootstrap-focus-style;
    }

    &:hover {
      @include make-card-hover;
    }
  }

  &__session-header {
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: -1rem;
  }

  &__who-and-where {
    align-items: center;
    display: flex;
    justify-content: space-around;

    @include media-breakpoint-up(lg) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  $session-spot-spacing: 0.3125rem;

  &__spot-list {
    margin: {
      left: $spacer * 0.5;
      right: -$session-spot-spacing;
    }
  }

  &__spot-wrapper {
    padding: {
      left: $session-spot-spacing;
      right: $session-spot-spacing;
    }

    ;

    margin: {
      top: 0.1875rem;
      bottom: 0.1875rem;
    }

    ;
  }

  &__spot {
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: rgb(246 246 246 / 50%);
    min-height: 1.5rem;

    &--empty {
      border: 1px dashed rgb(80 93 104 / 25%);
      border-style: dashed;
    }

    &--selected {
      background-color: #ecf5ff;
      color: $primary;
    }
  }
}

.kip-question-editor {
  overflow: visible !important;

  .kip-toolbar {
    .kip-toolbar-item {
      color: $gray-700;
      border: none;

      &:hover {
        cursor: pointer;
        background-color: $gray-200;
        color: $primary;
      }
    }
  }

  .kip-text-area {
    min-height: 100px;
  }
}

@use "sass:color";

.kip-activity-history {
  $self: &;

  align-items: stretch;

  &--active {
    .text-danger,
    .text-warning,
    .text-success,
    .text-muted,
    .text-secondary {
      color: $white !important; // Unset type colour utility on hover
    }

    #{$self}__icon {
      color: $white;
    }

    #{$self}__title,
    #{$self}__details {
      border-color: $secondary;
    }

    #{$self}__title {
      background-color: $secondary;
      color: $white;
    }
  }

  &__entry {
    &:hover {
      cursor: pointer;
      background-color: $kip-blue-100;
      background-image: none;

      .text-danger,
      .text-warning,
      .text-success,
      .text-secondary {
        color: inherit !important; // Unset type colour utility on hover
      }
    }

    transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  }

  &--segment-width {
    width: 48px;
  }

  #{$self}__title,
  #{$self}__details {
    border-left: 4px solid;
    border-color: transparent;

    #{$self}--segment-width {
      width: 44px;
      min-width: 44px;
    }
  }

  &__icon {
    color: $secondary;
  }

  &__score-input {
    width: 6em;
  }

  &__percent {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--success {

    #{$self}__title,
    #{$self}__details {
      border-color: $kip-green-200;
    }

    &:hover {
      cursor: pointer;
      color: $success;
      background-color: $kip-green-100;
    }

    &#{$self}--active {

      #{$self}__title,
      #{$self}__details {
        border-color: $kip-green-500;
      }

      #{$self}__title {
        background-color: $kip-green-500;
        color: $white;

        &:hover {
          background-color: color.adjust($kip-green-500, $lightness: -5%);
        }
      }
    }
  }

  &--warning {

    #{$self}__title,
    #{$self}__details {
      border-color: $kip-orange-200;
    }

    &:hover {
      cursor: pointer;
      color: $warning;
      background-color: $kip-orange-100;
    }

    &#{$self}--active {

      #{$self}__title,
      #{$self}__details {
        border-color: $kip-orange-500;
      }

      #{$self}__title {
        background-color: $kip-orange-500;
        color: $white;

        &:hover {
          background-color: color.adjust($kip-orange-500, $lightness: -5%);
        }
      }
    }
  }

  &--danger {

    #{$self}__title,
    #{$self}__details {
      border-color: $kip-red-200;
    }

    &:hover {
      cursor: pointer;
      color: $danger;
      background-color: $kip-red-100;
    }

    &#{$self}--active {

      #{$self}__title,
      #{$self}__details {
        border-color: $kip-red-500;
      }

      #{$self}__title {
        background-color: $kip-red-500;
        color: $white;

        &:hover {
          background-color: color.adjust($kip-red-500, $lightness: -5%);
        }
      }
    }
  }

  &--opened {

    #{$self}__title,
    #{$self}__details {
      border-color: $gray-200;
    }

    &#{$self}--active {

      #{$self}__title,
      #{$self}__details {
        border-color: $secondary;
      }

      #{$self}__title {
        background-color: $secondary;
        color: $white;

        &:hover {
          background-color: color.adjust($secondary, $lightness: -5%);
        }
      }
    }
  }

  &--no-hover {
    &:hover {
      color: unset;
      background-color: unset;
      cursor: unset;
    }
  }
}

.kip-toggle-eye-slash {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 100;
  cursor: pointer;

  svg {
    height: 25px;
    width: 25px;
  }
}

$hover-color: $kip-blue-100;
$active-color: $light;

.kip-explore,
.kip-activity-selector {
  .kip-explore-menu {
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $box-shadow;

    .kip-activity-block {
      margin-left: -1px;
    }

    &.kip-activity,
    .kip-activity {
      &:hover {
        background-color: $hover-color;
      }

      box-shadow: -9px 0 11px -8px $gray-300;
      color: $secondary;
      font-size: 1.06em;
      cursor: pointer;
      background: $white;
      margin-top: -1px;
      transition: background-color 0.14s ease-in,
      border-left 0.14s ease-in;
      border: 1px solid $gray-300;

      &~.kip-activity-block>.kip-activity~* {
        font-weight: normal;
        margin-left: 0.975em;
      }
    }

    // 1st level headings
    &>.kip-activity:first-of-type,
    &>.kip-activity-block>.kip-activity {
      margin-bottom: 0 !important;
      background: $gray-50;
      text-transform: uppercase;
      font-size: 1em;
      letter-spacing: 1.35px;
      padding: 1.5em 1em;
      font-weight: bolder;
      border-radius: 4px 4px 0 0 !important;

      &:hover {
        background-color: $hover-color;
      }
    }

    .kip-activity--expanded {
      color: $dark;
      font-weight: bolder;
      border-bottom-left-radius: 8px;
    }

    &--scrollable {
      overflow: auto;
    }

    &__content {
      flex: 1;
      overflow-x: hidden;

      &--help {
        color: $black;
        font-size: 1.15em;
        line-height: 1.75;
      }
    }

    &__wrapper {
      display: flex !important;
      flex-direction: column !important;
    }

    &--help {
      @include image-widths;

      table {
        @extend .table;
        @extend .table-striped;
      }
    }
  }
}

.kip-lesson-plan .kip-explore-menu {
  box-shadow: none;
}

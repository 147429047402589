@use "sass:math";

.kip-info {
  $vertical-spacer: $spacer;
  $vertical-spacer--descendents: (math.div($spacer, 2));

  display: flex;
  flex-direction: column;
  margin-bottom: $vertical-spacer;

  &__heading {
    @include make-overline-style($color: $primary);

    margin-bottom: $vertical-spacer--descendents;
  }

  &__entry {
    @include make-definition-style;

    margin-bottom: $vertical-spacer--descendents;
  }

  &__subtitle {
    @include make-body-alternate-style;
  }

  &--auto {
    @extend .kip-info;
  }
}

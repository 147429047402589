.kip-awards {
  &__btn {
    width: 3em;
    height: 3em;
    border: 3px solid;
    border-color: transparent;
    transition: border-color 0.15s;
    border-radius: 0.5em;

    &:hover {
      border-color: $primary;
      cursor: pointer;
    }

    &--active {
      border-color: $primary;
    }
  }

  &__display {
    width: 5.625em;
    height: 5.625em;
  }
}

$content-width: 252px;

.kip-color-picker-body {
  width: $content-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  .kip-color-title {
    margin: 0 auto 16px 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 14.22px;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: #505d68;
  }

  .color-btn-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    margin-bottom: 15px;

    .btn-color {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      border: unset;
    }

    .active-color {
      outline: 3px solid;
    }

    .btn-gradient-color {
      background: conic-gradient(from 180deg at 50% 50%, #f845d1 -47.86deg, #03e2ab 12.18deg, #9917df 70.48deg, #0087ff 131.43deg, #f86e45 190.32deg, #f8db45 249.7deg, #f845d1 312.14deg, #03e2ab 372.18deg);
    }
  }

  .rgba-text,
  .type-policy,
  .button-area,
  .hex-text {
    display: none !important;
  }

  .color-picker {
    max-width: 250px;
    border: unset !important;
    background: transparent !important;
  }

  .saturation-lightness {
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .cursor {
      height: 28px !important;
      width: 28px !important;
    }
  }

  &.simple-mode {
    .saturation-lightness {
      display: none;
    }

    .color-btn-body {
      padding: 0 14px;
    }

    .hue-alpha {
      border-radius: 4px;
      width: $content-width;
    }

    .alpha {
      border-radius: 4px;
    }

    .hue-alpha .right .hue,
    .hue-alpha .left {
      display: none !important;
    }
  }
}

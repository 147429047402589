@use "sass:map";

.kip-note {
  background-color: $white;
  display: flex;
  flex-direction: column;
  transition: all 0.15s linear;
  border: 1px solid transparent;

  &--timeline {
    &:hover {
      color: $primary;
      background: #ecf5ff;
      cursor: pointer;
    }
  }

  &--pinned {
    border: 1px solid $border-color;
    border-radius: $border-radius--large;
    padding: $spacer;
    height: 100%;

    &:hover {
      @include make-card-hover;
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;

    &--pinned {
      border: 1px solid $border-color;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__user {
    align-items: center;
    color: $raven;
    display: flex;
    font-size: 0.878rem;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.005rem;
    text-align: right;
  }

  &__category {
    @include make-overline-style($raven);
  }

  &__bubble {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border: 10px solid $black;
      border-color: transparent transparent $light $light;
      transform-origin: 0 0;
      transform: rotate(135deg);
      top: 0;
      left: 3rem;
    }
  }
}

.kip-note-timeline {
  $icon__font-size: 1rem;
  $icon__size: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  &__description {
    color: $raven;
    font-size: 13.56px;
    line-height: 18px;
    width: 100%;
    background-color: $light;
    border-radius: 4px;
    padding: 1em;
    transition: background-color 0.15s;
    margin-bottom: 1em;

    &:hover {
      cursor: pointer;
      background-color: map.get($alert-backgrounds, primary);
      border: 0;
    }

    &:not(:last-child) {
      margin-right: 1em;
    }

    &--activity {
      background: none;
      margin-bottom: 0;

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  &__notes--system &__description {
    background-color: $white;

    &:hover {
      cursor: pointer;
      background-color: map.get($alert-backgrounds, primary);
      border: 0;
    }
  }

  &__notes {
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 1em 1em 0;
    margin-bottom: 1em;
    display: flex;
    position: relative;
    background-color: $white;

    &--system {
      border: 1px solid transparent;
      padding: 0;
      background-color: transparent;
    }
  }

  &__entry {
    position: relative;

    &:not(:last-child) {
      &::before {
        background-color: #e8e8e8;
        bottom: -1rem;
        top: 1rem;
        content: "";
        margin-left: 10rem;
        position: absolute;
        width: 1px;
      }
    }
  }

  &__event {
    background-color: $white;
    position: relative;
    flex: 1;
  }

  &__event-type {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__date {
    min-width: 7.5rem;
  }

  &__accordion {
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &--hover {
      color: $muted;
      transition: color 0.15s;
    }

    &:hover {
      .kip-note-timeline__accordion--hover {
        color: $primary;
      }
    }
  }

  &__icon-wrapper {
    margin-left: 0.5em;
    margin-top: 0.5rem;
  }

  &__icon {
    align-items: center;
    background-color: #fff;
    display: inline-flex;
    height: $icon__size;
    margin-top: -0.25em;
    justify-content: center;
    line-height: 100%;
    padding: 0;
    position: relative;
    width: $icon__size;

    &--border {
      border-radius: 50%;
      border: 1px solid #e8e8e8;
    }
  }
}

.kip-monty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: $spacer * 2;

  &__asset {
    display: flex;
    height: 12rem;
    width: 100%;
    margin-bottom: $spacer * 2;

    @include media-breakpoint-up(lg) {
      height: 15em;
    }
  }

  &__headline {
    font-size: $h1-font-size;
    font-weight: 300;
    margin-bottom: $spacer * 0.75;
  }

  &__text {
    font-size: 1rem;
    margin-bottom: $spacer * 1.5;
  }
}

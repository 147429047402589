/* rubik-regular - latin */
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-regular.eot");

  /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-regular.woff2") format("woff2"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-regular.woff") format("woff"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-regular.ttf") format("truetype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-regular.svg#Rubik") format("svg");

  /* Legacy iOS */
}

/* rubik-500 - latin */
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500.eot");

  /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500.eot?#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500.woff2") format("woff2"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500.woff") format("woff"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500.ttf") format("truetype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500.svg#Rubik") format("svg");

  /* Legacy iOS */
}

/* rubik-italic - latin */
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-italic.eot");

  /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-italic.eot?#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-italic.woff2") format("woff2"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-italic.woff") format("woff"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-italic.ttf") format("truetype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-italic.svg#Rubik") format("svg");

  /* Legacy iOS */
}

/* rubik-500italic - latin */
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 500;
  src: url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500italic.eot");

  /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500italic.eot?#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500italic.woff2") format("woff2"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500italic.woff") format("woff"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500italic.ttf") format("truetype"),
    url("../../../assets/fonts/rubik-v14-latin/rubik-v14-latin-500italic.svg#Rubik") format("svg");

  /* Legacy iOS */
}

.text-rubik {
  font-family: Rubik, sans-serif;
  font-size: 16px;
}

@use "sass:math";
@use "sass:map";
@use "sass:color";

.kip-messages {
  background-color: $white;

  .kip-send {
    border-top: 1px solid $gray-400;

    .kip-input {
      border: 0;
      padding: 0;
    }

    .kip-selector {
      width: 15px;
    }

    .kip-toggle {
      &::after {
        display: none;
      }
    }

    .kip-icon {
      cursor: pointer;
      color: $primary;

      &.kip-recipients {
        color: $gray-700;
      }
    }
  }

  .kip-messages-inner {
    position: absolute;
    inset: 0;
    overflow-y: auto;

    .kip-message {
      border-bottom: 1px solid $gray-400;
      font-size: 0.9rem;

      .kip-icon {
        border-right: 1px solid $gray-400;
        min-width: 32px;
      }

      &.kip-chat {
        background-color: color.adjust($yellow, $lightness: 40%);
        font-weight: 600;
      }
    }
  }
}

.kip-chat-box {
  position: relative;

  &__log {
    min-height: 30vh;
    height: 100%;
  }

  &__log-overflow {
    overflow-y: auto;
    min-height: 0;
  }

  &__shadow {
    height: 10px;
    width: 100%;

    &--top {
      position: sticky;
      top: 0;
      background: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(96 97 112 / 16%) 100%);
    }

    &--bottom {
      position: absolute;
      top: 0;
      margin-top: -10px;
      background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, rgb(96 97 112 / 16%) 100%);
    }
  }
}

.kip-chat-bubble {
  padding: $spacer * 0.75;
  padding-top: $spacer * 0.5;
  background-color: map.get($alert-backgrounds, primary);
  border-radius: $spacer;
  margin: $spacer $spacer * 2;
  line-height: 1.25em;
  word-break: break-word;
  max-width: 35rem;
  width: calc(100% - 2rem);

  &+& {
    margin-top: 0;
  }
  
  &--sent {
    margin-right: math.div($spacer, 2);
    border-bottom-right-radius: 0;
    background-color: map.get($alert-backgrounds, primary);
    margin-left: auto;

    @include theme {
      color: theme-get("primary");
      background-color: theme-get("primary-200");
    }
  }

  &--received {
    margin-left: math.div($spacer, 2);
    border-bottom-left-radius: 0;
    background-color: map.get($alert-backgrounds, secondary);
    margin-right: auto;

    @include theme {
      color: theme-get("primary");
      background-color: theme-get("primary-50");
    }
  }

  &--success {
    background-color: map.get($alert-backgrounds, success);
    color: $success;

    @include theme {
      background-color: map.get($alert-backgrounds, success);
      color: $success;
    }
  }
  
  &--danger,
  &:has(.text-danger) {
    &,
    .text-primary {
      background-color: map.get($alert-backgrounds, danger);
      color: $danger !important;
    }
  }

  .emoji-mart-emoji {
    display: inline-flex;
    width: 33px;
    justify-content: center;
  }
}

.kip-chat-notification {
  border-top: 1px solid $card-border-color;
  border-bottom: 1px solid $card-border-color;
  line-height: 1;

  &:only-child,
  &:last-of-type {
    border-bottom: 0;
  }

  &+& {
    border-top: 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &--blue {
    color: $primary-medium;
  }
}

.kip-chat-input {
  width: 100%;
  color: $secondary;

  &:focus {
    outline: 0;
    box-shadow: $box-shadow-inset;
  }

  &__send-button {
    --bs-btn-hover-color: #{$white};
    --bs-btn-active-color: #{$white};

    height: 50%;
    border: 0;
    border-left: 1px solid $card-border-color;
    border-radius: 0;
    color: $white;
    background: $aurora-gradient;

    &[disabled],
    &.disabled {
      background: $white;
      color: $primary;
      opacity: 1;
    }

    @include theme {
      background: theme-get("gradient-solid");
      color: $white;
      border-radius: 0 0 20px;

      &[disabled],
      &.disabled {
        background: $white;
        color: theme-get("primary");
        opacity: 1;
      }
    }

    &:hover {
      background-color: map.get($alert-backgrounds, primary);
    }
  }

  &__emoji-toggle {
    height: 50%;

    --bs-btn-hover-bg: #{map.get($alert-backgrounds, primary)};

    &:hover {
      background-color: map.get($alert-backgrounds, primary);

      @include theme {
        --bs-btn-hover-bg: #{theme-get("primary-100")};

        background-color: theme-get("primary-100");
      }
    }

    // @media (pointer: none) {
    //   display: none; // Hide picker if no mouse
    // }
  }

  &__recipients {
    max-width: 55px;

    span {
      text-overflow: clip;
      overflow: hidden;
      display: inline-block;
      width: 37px;
    }

    &.dropup .dropdown-toggle::after {
      position: absolute;
      top: 11px;
      right: 3px;
    }
  }

  &__emoji-picker {
    position: absolute;
    bottom: 5em;
    left: 0;
    z-index: 1;
  }
}

.kip-chat-ai-help {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
}

.kip-chat-ai-help:hover {
  background-color: #e6f3ff;
}
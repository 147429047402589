.kip-loader {
  .kip-image {
    height: 50px;
  }
}

.kip-loader--session {
  animation: 1.5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

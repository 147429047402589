:root {
  --app-font-stack: "Open Sans",
  #{$font-family-sans-serif};
  --base-font-size-compact: 14px;
}

body {
  font-family: var(--app-font-stack);
  font-size: var(--base-font-size-compact);
}

small,
.small {
  font-size: calc(var(--base-font-size-compact) * 0.889);
  letter-spacing: 0.004em;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
}

.kip-text {
  &__heading--strong {
    @include make-header-text;
  }

  &__heading--secondary {
    font-weight: 300;
  }
}

.close {
  opacity: 1;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.5;
  }
}

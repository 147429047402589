.kip-dashboard {
  .kip-session-summary-container {
    .kip-session-summary {
      .kip-my-day {
        min-width: 200px;

        .kip-my-day--text {
          @include make-session-text;

          font-weight: 600;
          line-height: 19px;
        }

        .kip-my-day--btn {
          @include make-session-text;

          line-height: 23px;
          letter-spacing: 0.054em;
        }
      }

      .kip-lesson-summary {
        @include make-student-chip;

        th,
        td {
          border: 0;
        }

        &>.kip-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          min-width: 0;
        }

        &.kip-planned,
        .kip-homework-completed {
          .kip-icon {
            color: $success;
          }
        }

        &.kip-unplanned,
        .kip-homework-outstanding {
          .kip-icon {
            color: $danger;
          }
        }

        &.kip-absent {
          cursor: default;
          opacity: 0.5;

          .kip-avatar {
            background-color: $gray-600;
          }
        }

        &:hover {
          &:not(.kip-absent) {
            background-color: $gray-200;
          }
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .kip-session-summary {
        .kip-lesson-summary {
          flex: 0;
          min-width: unset;
          max-width: unset;
        }
      }
    }

    &:last-child {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    position: absolute;
    z-index: 1002;
    background-color: rgb(255 255 255 / 50%);
  }
}

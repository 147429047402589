@use "sass:color";

$number-box-width: 12.5%;
$number-box-height: 1.75em;
$colour-block-width: 4px;

.kip-overview-card {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 33.3333%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 337px;
  }

  &__grid {
    height: calc(#{$number-box-height} * 2);
    width: 100%;
  }

  &__number {
    @include question-navigation;

    width: $number-box-width;
    height: $number-box-height;
    line-height: $number-box-height;
    margin: 0;
    pointer-events: none;
    display: inline-block;
    text-align: center;

    &.kip-visited {
      &.kip-attempts-0 {
        background-color: color.adjust($border-color, $lightness: 5%);
      }
    }
  }

  &__button {
    line-height: 1.5rem;
    max-height: 3.25em;
    overflow: hidden;
  }
}

// Adjust number of cards in a row depending on number of students

[class*="kip-student-count-"] {
  // For total 6+ students in a lesson

  .kip-overview-card {
    @media only screen and (width >=1366px) {
      width: 16.6667%;
    }
  }
}

@include media-breakpoint-up(lg) {
  // For 1-5 students in a lesson

  .kip-student-count-1 {
    .kip-overview-card {
      width: 100%;
      max-width: 337px;
    }
  }

  .kip-student-count-2 {
    .kip-overview-card {
      width: 50%;
    }
  }

  .kip-student-count-3 {
    .kip-overview-card {
      width: 33.3333%;
    }
  }

  .kip-student-count-4 {
    .kip-overview-card {
      width: 25%;
    }
  }

  .kip-student-count-5 {
    .kip-overview-card {
      width: 20%;
    }
  }
}

$kip-card__min-height: 12.5rem;
$kip-card__padding--x: 1rem;
$kip-card__padding--y: 1rem;
$kip-card__padding: $kip-card__padding--y $kip-card__padding--x;
$card__corners--static: 2px;
$card__corners--summary: 4px;
$card__header--padding: $spacer * 1.5;
$card__tab-card--border: 1px solid #3889d4;
$card__tab-card--shadow: 0 2px 4px rgb(56 137 212 / 25%);
$card-link__tab-card--background: #fefefe;

@mixin make-base-tab-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.2s linear;
}

@mixin tab-card {
  @include make-base-tab-card;

  padding: $kip-card__padding;

  &:hover {
    border: $card__tab-card--border;
    box-shadow: $card__tab-card--shadow;
    text-decoration: none;
  }
}

@mixin tab-card-link {
  @include make-base-tab-card;

  align-items: center;
  background: $card-link__tab-card--background;
  border-style: dashed;
  color: $primary;
  justify-content: center;
  min-height: 215px;

  &:hover {
    border: $card__tab-card--border;
    box-shadow: $card__tab-card--shadow;
  }
}

.kip-card-enrolment {
  @include tab-card;
}

.kip-card-enrolment-link {
  @include tab-card-link;
}

@mixin make-kip-card-base {
  @extend .card;

  background-color: $white;
  color: $body-color;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.kip-card-link {
  @include make-kip-card-base;

  &__text {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.125em;
    text-transform: uppercase;
  }
}

.kip-card {
  @include make-kip-card-base;

  &--summary {
    border: 1px solid rgb(0 0 0 / 12.5%);
    border-radius: $card__corners--summary;
    box-shadow: $box-shadow-sm;
    padding: $kip-card__padding;
  }

  &--color {
    color: $primary;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__header {
    border-bottom: 0;
    padding-bottom: $card__header--padding;
    align-items: center;
    display: flex;
    flex-direction: row;

    // @HACK -- Target Fort Awesome Lib Class
    .ng-fa-icon {
      height: $h2-font-size;
      line-height: 100%;
      color: $primary;
    }
  }
}

$table-border-color: #dee2e6;
$table-body-color: #505d68;
$table-body-font-weight: 600;
$table-header-font-weight: 400;

.kip-table {
  // border-bottom: 1px solid $table-border-color !important; // Override .table-bordered

  // svg { // May cause issues with LMS
  //   height: 40px;
  // }

  &.table:not(first-child) {
    border-top: 0;
  }

  thead {
    border-top: 0;

    th {
      border-bottom: 1px solid $table-border-color;
      color: $primary;
      font-weight: $table-header-font-weight;
      padding: $spacer $spacer * 0.75;
    }
  }

  tbody {
    border-top: 0;

    td {
      border-bottom: 0;
      border-top: 0;
      color: $table-body-color;
      font-weight: $table-body-font-weight;
      padding: $spacer $spacer * 0.75;
    }
  }

  &__cell {
    &--choice {
      width: 1rem;
    }
  }
}
/* stylelint-disable custom-property-pattern */

.table-striped {
  --bs-table-striped-bg: #{$gray-50};
}

.table-no-stripes {
  --bs-table-striped-bg: none;
  ----bs-table-bg: none;
}

.table-primary {
  --bs-table-striped-bg: #{$kip-blue-50};
  --bs-table-bg: #{$kip-blue-50};
}

.table-info {
  --bs-table-striped-bg: #{$kip-teal-100};
  --bs-table-bg: #{$kip-teal-100};
}

.table-danger {
  --bs-table-striped-bg: #{$kip-red-50};
  --bs-table-bg: #{$kip-red-50};
}

.table-warning {
  --bs-table-striped-bg: #{$kip-orange-100};
  --bs-table-bg: #{$kip-orange-100};
}

.table-success {
  --bs-table-striped-bg: #{$kip-green-100};
  --bs-table-bg: #{$kip-green-100};
}

.table-danger,
.table-warning,
.table-success,
.table-light {
  --bs-table-border-color: inherit;
}

.table-white {
  --bs-table-striped-bg: #{$white};
  --bs-table-bg: #{$white};
}

.table-active {
  --bs-table-striped-bg: #{$kip-blue-40};
  --bs-table-bg: #{$kip-blue-40};
  --bs-table-active-bg: none;
}



@import "projects/global-styles/variables";

.image-uploader-body {
  .image-uploader-drop-area {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #dfdfdf;
    border-radius: 20px;
    cursor: pointer;

    .label-title {
      max-width: 330px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 20.25px;
      line-height: 125%;
      letter-spacing: 0.25px;
      color: #00549f;
      cursor: pointer;
    }
  }

  .available-file-info {
    margin: 16px 0 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 12.642px;
    line-height: 150%;
    letter-spacing: 0.4px;
    color: #505d68;
  }

  &.tutor-uploader .image-uploader-drop-area {
    background-color: $kip-blue-50;
    border-color: $kip-blue-500;
  }
}

.image-uploader-drop-area-small {
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin: 10px auto;
  padding: 10px;
  overflow-y: auto;
}

.image-uploader-drop-area.highlight,
.image-uploader-drop-area-small.highlight {
  border: 1px solid #3889d4;
  box-shadow: 0 0 0 0.2rem rgb(0 84 159 / 25%);
}

.image-uploader-form {
  margin-bottom: 10px;
}

.image-uploader-gallery {
  margin-top: 10px;
}

.image-uploader-problems {
  margin-top: 10px;
}

.image-uploader-gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.image-uploader-file {
  display: none;
}

.image-uploader-progress {
  margin: 10px;
}

.image-selected {
  border-width: 3px;
  border-color: red;
  border-style: solid;
}

// Styles ngTooltip

.tooltip {
  .tooltip-inner {
    background-color: $secondary;
  }

  &.bs-tooltip-top .tooltip-arrow::before,
  &.bs-tooltip-bottom .tooltip-arrow::before {
    border-top-color: $secondary;
    border-bottom-color: $secondary;
  }

  &.bs-tooltip-end .tooltip-arrow::before,
  &.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: $secondary;
    border-right-color: $secondary;
  }

  @media (hover: none) {
    // Hide tooltips for touch devices

    .tooltip {
      display: none;
    }
  }
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src:
    local("Open Sans Light"),
    local("OpenSans-Light"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300.woff2") format("woff2"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300.woff") format("woff");

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src:
    local("Open Sans Light Italic"),
    local("OpenSans-LightItalic"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300italic.woff2") format("woff2"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-300italic.woff") format("woff");

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src:
    local("Open Sans Italic"),
    local("OpenSans-Italic"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-italic.woff2") format("woff2"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-italic.woff") format("woff");

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src:
    local("Open Sans Regular"),
    local("OpenSans-Regular"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-regular.woff2") format("woff2"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-regular.woff") format("woff");

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src:
    local("Open Sans SemiBold"),
    local("OpenSans-SemiBold"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600.woff2") format("woff2"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600.woff") format("woff");

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src:
    local("Open Sans SemiBold Italic"),
    local("OpenSans-SemiBoldItalic"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600italic.woff2") format("woff2"),
    url("~/../global-assets/fonts/open-sans/open-sans-v17-latin-600italic.woff") format("woff");

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* varela-round - latin */
@font-face {
  font-family: "Varela Round";
  font-style: normal;
  src:
    local("Varela Round"),
    local("VarelaRound"),
    url("~/../global-assets/fonts/varela-round-latin.woff2") format("woff2"),

    /* Chrome 26+, Opera 23+, Firefox 39+ */
}

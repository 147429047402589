.kip-star-rating {
    direction: rtl;

    &__star {
        color: $muted;
        cursor: pointer;
        font-size: 1em;
        text-decoration: none;
        transition: all 0.5s;
        margin: 4px;
        transform: scale(1);

        &:hover {
            color: gold;
            transform: scale(1.3);
        }
    }

    .s1:hover~fa-icon {
        color: gold;
    }

    .s2:hover~fa-icon {
        color: gold;
    }

    .s3:hover~fa-icon {
        color: gold;
    }

    .s4:hover~fa-icon {
        color: gold;
    }

    .s5:hover~fa-icon {
        color: gold;
    }
}
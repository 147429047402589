.kip-auth {
  height: 100vh;

  &__login {
    box-shadow: 0 10px 30px #dfdfdf;
  }

  &__scene {
    background-image: url("~/assets/svg/kiplearn_login_gradient.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    &.tutorfly {
      background-image: url("~/assets/svg/kiplearn_login_gradient_tutorfly.svg");
    }
  }
}

.kip-observe {
  z-index: 100;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  transition: visibility 0s 0.6s;
  right: 0;
  animation: slide 0.2s ease-in;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    transition: background 0.3s 0.3s;
  }

  &__panel {
    transition: transform 0.3s 0.3s;
    transform: translate3d(100%, 0, 0);
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background: $white;
    z-index: 105;
    width: calc(100% - #{$sidebar-width});
    max-width: calc(100% - #{$sidebar-width} - 2rem);

    @include media-breakpoint-up(lg) {
      width: 65%;
      max-width: calc(100% - #{$sidebar-width-lg} - 2rem);
    }

    @include media-breakpoint-up(xl) {
      max-width: calc(100% - #{$sidebar-width-xl} - 2rem);
    }
  }

  &__content {
    margin: 0;
    background-color: $light;

    &--border {
      margin: 1em;
      background-color: $white;
      border: 1px solid $border-color;
    }
  }

  &--show {
    visibility: visible;
    transition: visibility 0s 0s;

    &::after {
      background: rgb(196 196 196 / 25%);
      transition: background 0.3s 0s;
    }

    .kip-observe__panel {
      transform: translate3d(0, 0, 0);
      transition-delay: 0s;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  75% {
    transform: translateX(0%);
  }
}

.kip-changes {
  &__entry {
    &:not(:first-of-type) {
      margin-top: 4em;
    }
  }

  h3,
  h4 {
    font-weight: bold;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.25em;
    margin: 1.25em 0 0.75em;
  }
}

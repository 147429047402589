@import "../variables";

.tutor-uploader-modal-dialog-class {
  min-width: 750px !important;
  max-width: 1200px !important;
  width: 90vw !important;

  @media (width <= 1350px) {
    max-width: 90vw !important;
  }
}

.tutor-uploader-modal {
  min-height: 70vh;

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 16px;
  }

  .modal-body {
    padding-bottom: 16px;
    max-height: 70vh;
    min-height: 70vh;
  }

  .kip-tutor-uploader-preview {
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .kip-tutor-uploader-preview,
  .kip-tutor-uploader-content {
    width: calc(50% - 8px);
  }

  .kip-tutor-uploader-content .image-uploader-body .available-file-info {
    margin-bottom: 0;
  }

  .kip-uploaded-file-list-body {
    border: unset !important;
  }

  .tutor-uploader-border {
    border: 1px solid $gray-400;
  }

  .empty-uploader-text {
    font-style: normal;
    font-weight: 400;
    font-size: 20.25px;
    line-height: 125%;
    letter-spacing: 0.25px;
    color: $gray-700;
  }

  .kip-tutor-uploader-content-header {
    box-shadow: 0 4px 4px rgb(212 212 212 / 50%);
    border-bottom: 1px solid $gray-400;
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    div {
      padding: 13px;
      text-align: center;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14.22px;
      line-height: 150%;
      letter-spacing: 0.25px;
      color: #004188;
    }

    .active-uploading-type {
      background-color: $kip-blue-50;
    }
  }

  .kip-tutor-uploader-content-body {
    padding: 16px;
  }

  .border-r-4 {
    border-radius: 4px;
    overflow: hidden;
  }

  .kip-uploaded-files-body {
    height: 100%;
    max-height: 100%;
    margin-top: 16px;
    overflow: auto;
    border-radius: 4px;
  }

  .kip-uploaded-file-list-body .img-body {
    cursor: pointer;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

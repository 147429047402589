.kip-footer {
  background-color: $white;
  border-top: 1px solid $gray-400;

  .kip-title {
    color: $primary;
    font-weight: 500;
  }

  .kip-divider {
    font-weight: 300;
  }
}

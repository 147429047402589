.kip-sorting-horizontal {
  .kip-option {
    display: inline-block;
    width: initial;
    height: 100%;
    cursor: grab;

    p {
      margin-block: 0;
    }
  }
}

.kip-sorting-layout {
  .kip-column {
    flex: 1;

    .kip-option,
    .kip-option-selected {
      cursor: grab;
      font-size: 18px;
      letter-spacing: 0.05em;
      text-shadow: none;
      padding: 1.1rem;
      border-radius: 8px;
      white-space: nowrap;
      text-align: center;
      min-width: 4rem;
      box-shadow: $box-shadow-btn;

      &:not([class*="alert-"]) {
        transition: background-color 0.15s;
        color: $primary;
        background-color: $white;

        @include theme {
          color: theme-get("primary");
        }

        &:hover,
        &:focus {
          background-color: $aurora-primary-100;

          @include theme {
            background-color: theme-get("primary-100");
          }
        }
      }

      &.cdk-drag-placeholder {
        visibility: hidden;
      }

      p {
        margin-block: 0;
      }
    }

    &.kip-target {
      transition: background-color 0.15s;
      background-color: $aurora-primary-100;
      border-radius: 20px;
      padding: 0.5rem;
      padding-bottom: 0.25rem;
      border: 4px solid transparent;

      @include theme {
        background-color: theme-get("primary-100");
      }

      &:has([class*="alert-"]) {
        border-color: $gray-400;
        background: none;

        @include theme {
          background: none;
          border-color: $gray-400;
        }
      }

      .kip-option-selected {
        transition: background-color 0.15s, border-color .15s;
        text-align: center;
        border-radius: 8px;
        padding: 1.1rem;
        border: 1px solid transparent;

        &[class*="alert-"] {
          box-shadow: none;
        }

        &:not([class*="alert-"]) {
          background-color: $white;
          transition: border-color 0.15s;

          &:hover {
            border: 1px solid $primary;
            background-color: $white;

            @include theme {
              border: 1px solid theme-get("primary");
            }
          }
        }
      }
    }
  }

  &.kip-selection {
    .kip-column {
      flex: auto;
      min-width: 43%;

      &.kip-spacer {
        min-width: 12%;

        .kip-icon {
          color: $primary;
          margin: auto;

          @include theme {
            color: theme-get("primary");
          }
        }
      }
    }
  }
}
// Colours per Figma and Eva Design System
$kip-blue-40: #ecf5ff;
$kip-blue-50: #e3f5fc;
$kip-blue-100: #cce7ff;
$kip-blue-400: #257bb9;
$kip-blue-500: #00549f;
$kip-blue-700: #003072;
$kip-green-50: #f0fff4;
$kip-green-100: #d3f8df;
$kip-green-200: #a9f2c8;
$kip-green-400: #4eb48d;
$kip-green-500: #177060;
$kip-red-50: #fcf1eb;
$kip-red-100: #fae4d8;
$kip-red-200: #f5c4b3;
$kip-red-400: #b65c53;
$kip-red-500: #a03535;
$kip-red-550: #89262f;
$kip-red-600: #731a2a;
$kip-teal-50: #f3fffb;
$kip-teal-100: #e3fbf2;
$kip-teal-500: #2f7683;
$kip-orange-50: #fffcf5;
$kip-orange-100: #fbecc9;
$kip-orange-200: #f7d594;
$kip-orange-400: #d48c35;
$kip-orange-500: #9e4700;
$gray-50: #f9f9f9;
$gray-100: #f6f6f6;
$gray-200: #f1f1f1;
$gray-300: #e8e8e8;
$gray-400: #dfdfdf;
$gray-500: #bebebe;
$gray-600: #9c9c9c;
$gray-700: #797979;
$gray-800: #707070;
$gray-900: #424242;
$gray-1000: #363738;
$kip-white: #fff;

// Custom gray for text

$raven: #505d68;

// Set variables for SCSS

$primary: $kip-blue-500;
$primary-medium: $kip-blue-400;
$secondary: $raven;
$success: $kip-green-500;
$warning: $kip-orange-500;
$danger: $kip-red-500;
$muted: $gray-700;
$dark: $gray-900;
$light: $gray-100;
$white: $kip-white;

// Remap Bootstrap $theme-colours

$theme-colors: (
  "primary": $kip-blue-500,
  "primary-medium": $kip-blue-400,
  "secondary": $raven,
  "success": $kip-green-500,
  "info": $kip-teal-500,
  "warning": $kip-orange-500,
  "danger": $kip-red-500,
  "light": $gray-100,
  "muted": $gray-600,
  "dark": $gray-900
);

// Custom map for Alert backgrounds/tints

$alert-backgrounds: (
  "primary": $kip-blue-50,
  "secondary": $gray-100,
  "success": $kip-green-100,
  "info": $kip-teal-100,
  "warning": $kip-orange-100,
  "danger": $kip-red-100
);
$alert-dark-backgrounds: (
  "secondary": $gray-200,
  "primary": $kip-blue-100,
  "success": $kip-green-200,
  "warning": $kip-orange-200,
  "danger": $kip-red-200
);
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.25,
  7: $spacer * 3.5,
  8: $spacer * 3.75,
  9: $spacer * 4,
  10: $spacer * 4.25,
);



// Bootstrap colour css variables
:root {
  --bs-dark-rgb: 50, 50, 50;
}

// Typography
$root__type--small: 0.89rem;
$h1-font-size: 1.424rem;
$h2-font-size: 1.266rem;
$h3-font-size: 1.125rem;
$h4-font-size: 1rem;
$h5-font-size: 0.9rem;

// Box shadows
$box-shadow-sm: 0 0 1px rgb(40 41 61 / 4%),
  0 2px 4px rgb(96 97 112 / 16%);
$box-shadow-left: -2px 0 4px rgb(212 212 212 / 25%);
$box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%);
$box-shadow-danger: 0 0 0 0.2rem rgb(228 96 109 / 25%);
$box-shadow-inset: inset 0 0 0 0.2rem rgb(0 84 159 / 25%);
$box-shadow-btn: 0 -2px 1px rgb(40 41 61 / 4%),
  0 2px 4px rgb(96 97 112 / 16%);
$box-shadow-lg: 2px 4px 8px rgb(212 212 212 / 25%);
$highlight-shadow: #ccddec;

// Borders
$border-radius--small: 4px;
$border-radius--large: 10px;
$border-color: #e7e7e7;

// Buttons
$button-height: 44px;

// Theme colour variables and maps

$aurora-gradient-100-315deg: linear-gradient(315deg, #19837e 0%, #00549f 43.75%, #280684 100%);
$aurora-gradient-20-315deg: linear-gradient(315deg, rgb(154 234 230 / 20%) 0%, rgb(100 175 241 / 20%) 43.75%, rgb(146 115 230 / 20%) 100%), #fff;
$aurora-gradient: linear-gradient(358deg, #19837e 0%, #00549f 43.75%, #280684 100%);
$aurora-gradient-25: linear-gradient(315deg, rgb(154 234 230 / 25%) 0%, rgb(100 175 241 / 25%) 43.75%, rgb(146 115 230 / 25%) 100%),
  #fff;
$aurora-gradient-50: linear-gradient(315deg, rgb(154 234 230 / 50%) 0%, rgb(100 175 241 / 50%) 43.75%, rgb(146 115 230 / 50%) 100%),
  #fff;
$aurora-background: linear-gradient(315deg, rgb(154 234 230 / 20%) 0%, rgb(100 175 241 / 20%) 43.75%, rgb(146 115 230 / 20%) 100%),
  #fff;
$aurora-button-bg: linear-gradient(315deg, #280684 0%, #00549f 25%, #19837e 55%, #00549f 75%, #280684 100%);
$aurora-primary: $primary;
$aurora-primary-700: #003072;
$aurora-primary-400: #99cfff;
$aurora-primary-300: #b3dbff;
$aurora-primary-200: #cce7ff;
$aurora-primary-100: #e6f3ff;
$aurora-primary-50: #f2f9ff;
$sunset-gradient: linear-gradient(178deg, #aa53b8 2.88%, #c25535 100%);
$sunset-gradient-25: linear-gradient(135deg, rgb(170 83 184 / 25%) 2.88%, rgb(194 85 53 / 25%) 100%),
  #fff;
$sunset-gradient-50: linear-gradient(135deg, rgb(170 83 184 / 50%) 2.88%, rgb(194 85 53 / 50%) 100%),
  #fff;
$sunset-background: linear-gradient(135deg, rgb(170 83 184 / 20%) 2.88%, rgb(194 85 53 / 20%) 100%),
  #fff;
$sunset-button-bg: linear-gradient(315deg, #aa53b8 0%, #c25535 51%, #aa53b8 100%);
$sunset-primary: #b24525;
$sunset-primary-700: #a83b1b;
$sunset-primary-400: #fcc5b5;
$sunset-primary-300: #fdd4c8;
$sunset-primary-200: #fde2da;
$sunset-primary-100: #fff1ed;
$sunset-primary-50: #fef7f5;
$electric-storm-gradient: linear-gradient(178deg, #6f3ae1 2.88%, #620c90 98.14%);
$electric-storm-gradient-25: linear-gradient(135deg, rgb(58 66 225 / 25%) 2.88%, rgb(98 12 144 / 25%) 98.14%),
  #fff;
$electric-storm-gradient-50: linear-gradient(135deg, rgb(58 66 225 / 50%) 2.88%, rgb(98 12 144 / 50%) 98.14%),
  #fff;
$electric-storm-background: linear-gradient(135deg, rgb(58 66 225 / 20%) 2.88%, rgb(98 12 144 / 20%) 98.14%),
  #fff;
$electric-storm-button-bg: linear-gradient(315deg, #6f3ae1 0%, #620c90 51%, #6f3ae1 100%);
$electric-storm-primary: #620c90;
$electric-storm-primary-700: #620c90;
$electric-storm-primary-400: #d6a2f2;
$electric-storm-primary-300: #e1baf6;
$electric-storm-primary-200: #ebd1f9;
$electric-storm-primary-100: #f5e8fc;
$electric-storm-primary-50: #faf3fd;
$lab-green-gradient: linear-gradient(21deg, #0f5f4b 0%, #088667 74.48%, #00bb8d 99.48%);
$lab-green-gradient-25: linear-gradient(82.84deg, rgb(74 207 140 / 25%) 0%, rgb(117 237 166 / 25%) 100%),
  #fff;
$lab-green-gradient-50: linear-gradient(82.84deg, rgb(74 207 140 / 50%) 0%, rgb(117 237 166 / 50%) 100%),
  #fff;
$lab-green-background: linear-gradient(82.84deg, rgb(74 207 140 / 20%) 0%, rgb(117 237 166 / 20%) 100%),
  #fff;
$lab-green-button-bg: linear-gradient(265deg, #0f5f4b 0%, #088667 37.5%, #00bb8d 51%, #088667 62.5%, #0f5f4b 100%);
$lab-green-primary: #0f5f4b;
$lab-green-primary-700: #105e57;
$lab-green-primary-400: #9af4dd;
$lab-green-primary-300: #b4f7e6;
$lab-green-primary-200: #cdf9ee;
$lab-green-primary-100: #e6fdf7;
$lab-green-primary-50: #f2fdfb;
$theme-map: null;
$themes: (
  aurora: ("primary": $primary,
  "primary-700": $aurora-primary-700,
  "primary-400": $aurora-primary-400,
  "primary-300": $aurora-primary-300,
  "primary-200": $aurora-primary-200,
  "primary-100": $aurora-primary-100,
  "primary-50": $aurora-primary-50,
  "gradient-solid": $aurora-gradient,
  "gradient-25": $aurora-gradient-25,
  "gradient-50": $aurora-gradient-50,
  "gradient-bg": $aurora-background,
  "gradient-looped": $aurora-button-bg),
  sunset: ("primary": $sunset-primary,
  "primary-700": $sunset-primary-700,
  "primary-400": $sunset-primary-400,
  "primary-300": $sunset-primary-300,
  "primary-200": $sunset-primary-200,
  "primary-100": $sunset-primary-100,
  "primary-50": $sunset-primary-50,
  "gradient-solid": $sunset-gradient,
  "gradient-25": $sunset-gradient-25,
  "gradient-50": $sunset-gradient-50,
  "gradient-bg": $sunset-background,
  "gradient-looped": $sunset-button-bg),
  electric-storm: ("primary": $electric-storm-primary,
  "primary-700": $electric-storm-primary-700,
  "primary-400": $electric-storm-primary-400,
  "primary-300": $electric-storm-primary-300,
  "primary-200": $electric-storm-primary-200,
  "primary-100": $electric-storm-primary-100,
  "primary-50": $electric-storm-primary-50,
  "gradient-solid": $electric-storm-gradient,
  "gradient-25": $electric-storm-gradient-25,
  "gradient-50": $electric-storm-gradient-50,
  "gradient-bg": $electric-storm-background,
  "gradient-looped": $electric-storm-button-bg),
  lab-green: ("primary": $lab-green-primary,
  "primary-700": $lab-green-primary-700,
  "primary-400": $lab-green-primary-400,
  "primary-300": $lab-green-primary-300,
  "primary-200": $lab-green-primary-200,
  "primary-100": $lab-green-primary-100,
  "primary-50": $lab-green-primary-50,
  "gradient-solid": $lab-green-gradient,
  "gradient-25": $lab-green-gradient-25,
  "gradient-50": $lab-green-gradient-50,
  "gradient-bg": $lab-green-background,
  "gradient-looped": $lab-green-button-bg)
);
$kip-lesson-color--success: $kip-green-500;
$kip-lesson-color--warning: $kip-orange-500;
$kip-lesson-color--danger: $kip-red-500;
@use "sass:color";

// Set background colours for each theme

@each $theme,
$map in $themes {
  $theme-map: $map;

  .theme-#{$theme} {
    background: theme-get("gradient-bg");
    background-size: 100% 100%;
  }
}

// Adjust typography and button/link colours

.kip-student,
.theme-aurora,
div[class^="kip-app theme-"] {
  font-family: Rubik, sans-serif;
  font-size: 16px;
  pointer-events: auto; // Allow keyboard boundary to be clicked through
  font-synthesis: none;
  
  small,
  .small {
    font-size: 14px;
    letter-spacing: 0.25px;
  }

  strong,
  b,
  .fw-bold {
    font-weight: 500 !important; // Override .fw-bold for Rubik and Firefox
  }

  .text-caption {
    font-size: 0.75em;
    letter-spacing: 0.4px;
  }

  .text-overline {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75em;
    letter-spacing: 1.5px;

    @include theme {
      color: theme-get("primary");
    }
  }

  .text-btn {
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 1.25px;
  }

  .rounded {
    border-radius: 0.5em !important; // Override .rounded
  }

  .h1,
  h1 {
    font-weight: 500;
    font-size: 1.75em;
    letter-spacing: -0.5px;
  }

  .h2,
  h2 {
    font-weight: 400;
    font-size: 1.56em;
    letter-spacing: -0.25px;
  }

  .h3,
  h3 {
    font-weight: 400;
    font-size: 1.37em;
  }

  .h4,
  h4 {
    font-weight: 400;
    font-size: 1.25em;
    letter-spacing: 0.25px;
  }

  .h5,
  h5 {
    font-weight: 400;
    font-size: 1.12em;
  }

  a {
    @include theme {
      color: theme-get("primary");

      &:hover {
        color: color.adjust(theme-get("primary"), $lightness: -15%);
      }
    }
  }

  .alert-primary {
    @include theme {
      color: theme-get("primary");
      border-color: theme-get("primary");
      background-color: theme-get("primary-200");
    }
  }

  .btn-primary {
    @include theme {
      transition: background-position 0.1s;
      border: 0;
      background: theme-get("gradient-looped");
      background-size: 200% auto;
      letter-spacing: 1.25px;
      font-size: 1em;
      font-weight: 500;

      &:hover:not([disabled]) {
        color: $white;
        background-position: 100%;
      }
    }
  }

  .btn-outline-primary {
    @include theme {
      color: theme-get("primary");
      border-color: theme-get("primary");

      &:hover {
        color: $white;
        background-color: theme-get("primary");
      }
    }
  }

  .card {
    border: 0;
    border-radius: $border-radius--large * 2;

    .card-body {
      padding: 1.5rem;
    }
  }

  // Custom checkbox

  @include theme {
    .form-check:not(.form-switch) {
      display: flex;
      align-items: center;
      padding: 0;

      .form-check-label {
        padding: 1em 0;
      }

      .form-check-input {
        min-width: 1.5em;
        min-height: 1.5em;
        margin: 1em;
        border-radius: 0.5em;
        border-color: theme-get("primary");
        position: relative;
      }

      .form-check-input:checked {
        background: theme-get("gradient-solid");
      }

      .form-check-input:checked::after {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        min-width: 1.5em;
        min-height: 1.5em;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.39395C14 1.6752 13.875 1.9252 13.6875 2.1127L5.6875 10.1127C5.5 10.3002 5.25 10.394 5 10.394C4.71875 10.394 4.46875 10.3002 4.28125 10.1127L0.28125 6.1127C0.09375 5.9252 0 5.6752 0 5.39395C0 4.83145 0.4375 4.39395 1 4.39395C1.25 4.39395 1.5 4.51895 1.6875 4.70645L5 7.9877L12.2812 0.706451C12.4688 0.518951 12.7188 0.393951 13 0.393951C13.5312 0.393951 14 0.831451 14 1.39395Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
      }
    }

  }
}
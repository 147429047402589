// Overrides for ngx-extended-pdf-viewer

@use "sass:color";

ngx-extended-pdf-viewer {
  .zoom {
    height: 100% !important;
  }

  // Checked checkbox
  .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
  .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
    height: 80%;
    left: 45%;
    width: 1px;
    transform: unset !important;
  }

  // Empty checkbox input
  .annotationLayer .buttonWidgetAnnotation.checkBox input,
  .annotationLayer .buttonWidgetAnnotation.radioButton input {
    background: white !important;
    border-radius: 0.5em;
    border: 1px solid $border-color !important;

    &:checked {
      background: color.adjust($success, $lightness: 50%) !important;
      border-color: color.adjust($success, $lightness: 50%) !important;
    }
  }

  .annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
    border-radius: 50%;
    height: 50%;
    left: 25% !important;
    top: 25% !important;
    width: 50%;
  }

  .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
  .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
  .annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
    background-color: #5a5a5a;
    content: "";
    display: block;
    position: absolute;
  }

  .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
    position: absolute !important;
    left: -1px !important;
    top: calc(60% - 4px) !important;
    height: 40% !important;
    width: 3px !important;
    background-color: $dark !important;
    content: "" !important;
    transform: translateX(10px) rotate(-45deg) !important;
    transform-origin: left bottom !important;
  }

  .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
    position: absolute !important;
    left: -1px !important;
    bottom: 4px !important;
    height: 3px !important;
    width: 90% !important;
    background-color: $dark !important;
    content: "" !important;
    transform: translateX(10px) rotate(-45deg) !important;
    transform-origin: left bottom !important;
  }
}

@import "../../../../global-styles/variables";

.kip-student-sidebar-content-body {
  display: flex;
  flex-direction: column;
  height: 100%;

  .kip-video--lesson {
    height: 120px;
  }
}

.sidebar-content-body-hidden {
  display: none !important;
}

.kip-peer-stream {
  display: none !important;
  visibility: hidden;
}

.kip-lesson-sidebar-wrapper-tablet {
  background-color: transparent;
  padding: 0 !important;

  .kip-lesson-sidebar {
    height: fit-content !important;
    position: fixed;
    right: 0;
    top: 7em;
    z-index: 100;
    border-radius: unset;
    box-shadow: unset !important;
    max-width: 307px !important;
    display: flex !important;
    flex-direction: row !important;
    background-color: transparent;

    .kip-video__button {
      height: 44px;
      width: 44px;

      fa-icon {
        display: flex;
      }

      // svg {
      //   height: 27px;
      //   width: 24px;
      // }
    }

    .action-btn-body {
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0 0 2px rgb(0 84 159 / 4%)) drop-shadow(0 4px 8px rgb(0 84 159 / 16%));
      height: fit-content;
      width: fit-content;

      .icon-body {
        &:first-of-type {
          border-top-left-radius: 20px;
        }

        &:last-of-type {
          border-bottom-left-radius: 20px;
        }

        height: 56px;
        width: 56px;
        min-height: 56px;
        min-width: 56px;
        max-height: 56px;
        max-width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: unset;
        outline: unset;
        color: $kip-blue-500;

        @include theme {
          color: theme-get("primary");
        }

        &.hand-acknowleged {
          background-color: $kip-green-200;
          color: $kip-green-500;
        }

        &.hand-raised {
          background-color: $kip-blue-100;
          color: $kip-blue-500;

          @include theme {
            background-color: theme-get("primary-200");
            color: theme-get("primary");
          }
        }

        &.active-action-btn {
          background-color: $kip-red-100;
          color: $danger;
        }
      }
    }

    &.kip-lesson-sidebar-reversed:not(.kip-lesson-sidebar-closed) {
      .icon-body {
        &:first-of-type {
          border-top-left-radius: 0;
          border-top-right-radius: 20px;
        }

        &:last-of-type {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 20px;
        }
      }
    }

    .action-btn-body-reversed {
      border-radius: 0 20px 20px 0;
    }

    .kip-video-wrapper {
      height: 123px;
      width: 251px;
      max-width: 251px;
      max-height: 251px;
      position: relative;

      .kip-video {
        &__tutor {
          height: 100%;
          border: unset;
          border-radius: unset;
        }

        &__student {
          height: 123px;
          border-radius: unset !important;
          transition: all 0.5s;

          &--inset {
            height: 59px;
            width: 61px !important;
            position: absolute !important;
            right: 0;
            bottom: 0;
            border-radius: 8px 8px 0 0;
          }
        }
      }
    }

    .kip-student-sidebar-content-body {
      height: fit-content;
      background-color: #fff;
      max-width: 251px;
      box-shadow: 0 4px 10px rgb(212 212 212 / 75%);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      z-index: 1;
    }

    .kip-student-sidebar-content-body-reversed {
      border-top-left-radius: 20px;
      border-top-right-radius: 0;
    }
  }

  .kip-lesson-sidebar-closed {
    .icon-body {
      &:first-of-type {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      &:last-of-type {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

    height: fit-content !important;
    width: fit-content !important;
    min-width: fit-content !important;
  }

  .kip-lesson-sidebar-reversed {
    flex-direction: row-reverse !important;
  }

  .kip-chat-box__log {
    min-height: 190px !important;
  }
}
@use "sass:math";
@use "sass:color";

$root__font-size--in-pixels: 16;

@function rem-calc($sizeInPixels) {
  $remSize: math.div($sizeInPixels, $root__font-size--in-pixels);

  @return #{$remSize}rem;
}

$kip-table__button--border-color: #c8c8c8;
$kip-paginator__button--border-color: $kip-table__button--border-color;
$kip-paginator__button--color: $raven;
$kip-paginator__button--width: rem-calc($sizeInPixels: 46);

@mixin btn-letter-spacing {
  letter-spacing: 0.025rem;
  text-transform: capitalize;
}

.btn-letter-spacing {
  @include btn-letter-spacing;
}

.kip-footer__button {
  @extend .btn;

  display: block;
  width: 100%;

  // btn-primary
  @include button-variant($primary, $primary);
  @include btn-letter-spacing;

  // Lesson planner styles
  &__action {
    background-color: #fff;
    border-color: $card-border-color;

    &:hover {
      background-color: $primary;
    }
  }
}

// @HACK: Override bootstrap outline button border color
[class*="btn-outline-"] {
  border-color: $kip-table__button--border-color;
}

.kip-paginator {
  &__button {
    @extend .btn;
    @include button-outline-variant($color: $kip-paginator__button--color,
      $color-hover: $white,
      $active-background: $primary,
      $active-border: color.adjust($primary, $lightness: -5%));

    border-color: $kip-paginator__button--border-color;
    width: $kip-paginator__button--width;

    &--width-only {
      width: $kip-paginator__button--width;
    }
  }
}

.btn-circle {
  height: 39px;
  width: 39px;
  border-radius: 100%;
}

.btn-link {
  &--small {
    font-size: 0.79rem;
    font-weight: 600;
  }

  &--form {
    padding: 0;
    font-weight: 600;
    font-size: 0.79rem;
    line-height: 16px;
    letter-spacing: 0.125em;
  }

  &--grey-hover {
    color: $secondary;

    &:hover {
      color: $dark;
      background-color: #f1f1f1;
    }

    &+.kip-dropdown__menu--top-right {
      margin-left: 0.85rem;
    }
  }
}

.kip-view-mode-button {
  align-items: center;
  display: flex;
  text-align: center;

  &--is-selected {
    background: #f5faff;
    border: 1px solid #3889d4;
    color: #00549f;
    opacity: 0.7;

    &:focus {
      @include bootstrap-focus-style;
    }
  }
}

.btn {
  --bs-btn-border-width: 0;

  &[class*="btn-outline-"] {
    --bs-btn-border-width: 1px;
  }

  &--icon {
    --bs-btn-hover-bg: #{$gray-200};
    --bs-btn-hover-color: #{$body-color};

    background: transparent;
    border-radius: 50%;
    color: $body-color;

    &:hover {
      background-color: $gray-200;
      cursor: pointer;
      user-select: none;
    }
  }
}

.kip-btn {
  --bs-btn-disabled-border-color: #{$border-color};
  --bs-btn-hover-color: #{$primary};
  --bs-btn-hover-bg: #{$kip-blue-40};
  --bs-btn-hover-border-color: #{$primary};
  --bs-btn-active-color: #{$primary};

  color: $primary;
  background-color: $white;
  border: 1px solid $border-color;

  @include theme {
    color: theme-get("primary");
  }

  &:active,
  &.active {
    &:not([class*="alert-"]) {
      border-color: #3889d4;
      background-color: $kip-blue-50;

      @include theme {
        background: theme-get("primary-100");
      }

      &:focus {
        @include bootstrap-focus-style;
      }
    }
  }

  &.selected {
    background: $kip-blue-40;

    @include theme {
      background: theme-get("primary-200");
    }
  }

  &:disabled,
  &.disabled {
    color: $secondary;
    pointer-events: none;
  }

  @media (hover: hover) {
    // No hover for touch devices

    &:hover {
      color: $primary;
      background: $kip-blue-40;
      border-color: $primary;

      @include theme {
        color: theme-get("primary");
        background: theme-get("primary-100");
        border-color: theme-get("primary");
      }
    }
  }

  &.btn-sm {
    font-size: 0.79rem;
    font-weight: bold;
  }

  &.btn-success {
    background-color: $kip-green-100;
    color: $kip-green-500;
  }

  &.btn-danger {
    background-color: $kip-red-100;
    color: $kip-red-500;
  }

  &.btn-outline-secondary {
    color: $secondary;

    &:hover {
      color: $secondary;
      background-color: $gray-100;
      border-color: $secondary;
    }
  }

  &.btn-outline-muted {
    color: $muted;

    &:hover {
      color: $secondary;
      background-color: $gray-100;
      border-color: $muted;
    }
  }

  &.btn-outline-danger {
    color: $kip-red-500;

    &:hover {
      border-color: $kip-red-500;
      background-color: $kip-red-100;
    }
  }
}

.kip-dashboard-toggle {
  margin-right: 13px;
  margin-left: 11px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.kip-tutor-solution-btn {
  border-color: transparent;

  &:active {        
    border-color: #3889d4;

    .kip-success-tutor-answer {
      background-color: $white;
    }
  }
}

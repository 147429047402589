.kip-generic-layout {
  .ng-select.ng-select-single .ng-select-container {
    min-height: 36px;
    height: initial;
  }

  .kip-input {
    display: inline-block;
    text-align: left;
    height: auto;
    width: 5rem;
    border: 1px solid #b1bbc4;

    &.form-control[readonly] {
      background-color: $white !important;
    }
    
    &:focus {
      @include bootstrap-focus-style;
    }
  }
  
  .katex .kip-input {
    transform: scale(0.97);
  }

  @include kip-input-width;

  .kip-text-area {
    resize: none;
    vertical-align: top;
  }

  table {
    margin: auto;
  }
}

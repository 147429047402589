.kip-tab-sheet {
  list-style-position: inside;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 3rem;

  &--small {
    height: 2rem;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.89rem;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    flex-grow: 1;
    text-align: center;

    &--small {
      font-size: calc(var(--base-font-size-compact) * 0.889);
    }

    &:hover {
      border-bottom: 1px solid $primary;
      color: $primary;
      margin-bottom: -1px;
    }

    &--active {
      border-bottom: 1px solid $primary;
      color: $primary;
      margin-bottom: -1px;
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    margin: auto;
  }
}

.kip-session-header {
  min-height: 3rem;
}

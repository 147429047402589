$spectrum-height: 2px;
$spectrum-green: #b8d335;
$spectrum-purple: #5352a3;
$spectrum-blue: #2aa7d5;
$spectrum-pink: #c36;
$spectrum-yellow: #fbbd19;
$spectrum-orange: #f48020;
$spectrum-image: linear-gradient(to right, $spectrum-green 16.6%, $spectrum-purple 0%, $spectrum-purple 33.2%, $spectrum-blue 0%, $spectrum-blue 49.8%, $spectrum-pink 0%, $spectrum-pink 66.6%, $spectrum-yellow 0%, $spectrum-yellow 83.2%, $spectrum-orange 0%, $spectrum-orange 100%);

.kip-navbar {
  height: 3em;
  width: 100%;
  position: relative;
  color: $white;
  background-color: $primary;

  @include theme {
    background: theme-get("gradient-solid");
    background-size: 100% 100%;
  }

  .kip-navbar-container {
    height: 100%;
    width: 100%;
    z-index: 102;

    .kip-navigator {
      cursor: pointer;
      height: 28px;
      width: 14px;
    }

    .kip-icon {
      color: $white;

      &:hover {
        color: $white;
      }
    }

    .kip-notifications {
      .kip-has-notifications {
        &::after {
          content: "";
          background-color: $orange;
          position: absolute;
          top: 0;
          right: 30%;
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 50%;
        }
      }
    }

    .kip-user {
      position: relative;

      .kip-avatar {
        background-color: $white;

        * {
          color: $primary;
        }
      }
    }
  }

  .kip-profile:hover {
    cursor: pointer;
  }

  .kip-spectrum {
    content: "";
    background: $spectrum-image;
    height: $spectrum-height;
  }

  @include media-breakpoint-down(xs) {
    .kip-logo-container {
      flex: 0 !important;
    }
  }

  .kip-nav-toggle-icon path {
    fill: #424242;
  }
}

@import "../variables";

.kip-uploaded-file-list-body {
  padding: 9px 8px;
  border-radius: 20px;
  border: 1px solid $gray-400;

  .img-body {
    background: $gray-50;
    border-radius: 8px;
    padding: 8px 16px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    img {
      height: 45px;
      width: 45px;
      min-width: 45px;
      max-width: 45px;
      margin-right: 16px;
      border-radius: 8px;
      overflow: hidden;
      object-fit: cover;
    }

    .title-info-body {
      width: calc(100% - 45px);
    }

    .file-name-body {
      margin-bottom: 14px;
    }

    .file-name {
      max-width: calc(100% - 110px);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray-1000;
      margin: 0;
    }

    fa-icon {
      cursor: pointer;
      margin-left: 18px;
    }

    .file-info {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $raven;

      .file-date-info {
        max-width: 350px;
        margin-left: 8px;
        font-size: 14px;
      }
    }
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .file-icon {
    height: 22px;
    width: 22px;
  }

  .file-loader-icon {
    cursor: default;

    path {
      fill: $kip-blue-500;
    }
  }

  .file-error-icon {
    cursor: default;

    path {
      fill: $kip-red-500;
    }
  }

  .file-success-icon {
    cursor: default;

    path {
      fill: $kip-green-400;
    }
  }

  .kip-file-loader-body {
    height: 8px;
    min-height: 8px;
    min-width: 100%;
    background: $gray-200;
    margin-top: 8px;
    border-radius: 8px;
    overflow: hidden;

    .kip-file-loader {
      height: 100%;
      background-color: $kip-blue-400;
    }
  }

  .edit-file-input {
    height: 44px;
    width: 100%;
    box-shadow: inset 0 0.5px 4px rgb(0 84 159 / 32%);
    border-radius: 4px;
    border: unset;
    outline: unset;
    padding: 8px;

    @media (width <= 1350px) {
      width: 230px;
    }
  }
}

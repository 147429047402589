.kip-word-picker-layout {
  width: fit-content;
  margin: auto;

  .kip-word {
    cursor: pointer;
    margin-right: 2px;
    margin-left: 2px;
    display: inline-block;
    transition: 0.15s background-color;

    &:hover {
      color: $primary;
      background-color: $aurora-primary-100;
      padding: 0 0.25rem;

      @include theme {
        color: theme-get("primary");
        background-color: theme-get("primary-100");
      }
    }

    &.kip-selected {
      color: $white;
      background-color: $primary;

      @include theme {
        background-color: theme-get("primary");
      }

      &:hover {
        color: $white;
      }
    }

    &.kip-word-correct {
      color: $green;
      background: $kip-green-100;
      margin-bottom: 1px;
    }
    
    &.kip-word-incorrect {
      margin-bottom: 1px;
      color: $red;
      background: $kip-red-100;
    }
  }

  &.kip-readonly {
    .kip-word {
      cursor: default;

      &:hover {
        color: $black;
      }
    }
  }
}

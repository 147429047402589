@use "sass:map";

html {
  height: 100%;

  body {
    background-color: $gray-100;
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
    touch-action: manipulation;

    .kip-app {
      overflow: auto;
      height: 100%;
      width: 100%;

      .kip-error {
        color: $gray-600;
        font-size: 22px;
      }

      .kip-row-number {
        width: 30px;
      }
    }

    .kip-session-dropdown {
      color: $primary;
      font-weight: bold;
      transition: background-color 0.3s;
      text-transform: unset;

      .dropdown {
        margin-bottom: -1px;
        border-bottom: 1px solid transparent;

        &:hover {
          cursor: pointer;
          user-select: none;
          border-bottom: 1px solid $primary;
          background-color: map.get($alert-backgrounds, primary);
        }

        &.show {
          border-bottom: 1px solid $primary;
          color: $primary;
          background-color: $gray-100;
        }
      }
    }

    .kip-vertical-container {
      display: block;
      height: 100%;
      max-height: 100%;
    }

    @media (width >=2000px) {
      .kip-xl-container {
        max-width: 2000px;
      }

      .container-fluid {
        max-width: 2000px;

        &--undo-max-width {
          width: 100% !important;
        }
      }
    }
  }
}

.kip-region-flag {
  height: 26px;
  width: 26px;
  display: inline-block;
}

.kip-no-select {
  user-select: none;
}

.kip-analogue-clock-layout {
  .kip-outer {
    stroke: #000;
    stroke-width: 2;
    fill: transparent;
  }

  .kip-center {
    fill: #000;
  }

  .kip-marker {
    stroke: #000;
    stroke-width: 1;

    &.kip-major {
      stroke-width: 2;
    }
  }

  .kip-number {
    font-size: 1.1rem;
    stroke: #000;
    stroke-width: 1;
    text-anchor: middle;
  }

  .kip-hour-hand {
    stroke: #000;
    stroke-width: 8;
  }

  .kip-minute-hand {
    stroke: #000;
    stroke-width: 4;
  }

  .kip-handle {
    stroke: transparent;
    fill: transparent;
  }
}

.kip-online-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: $kip-green-400;
  border: 2px solid $white;
  box-shadow: $box-shadow-btn;
  margin: 1px 1px -1px;
}

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 744px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);
$sidebar-width: 249px;
$sidebar-width-lg: 335px;
$sidebar-width-xl: 383px;

@use "sass:map";

.kip-header {
  background-color: $white;
  border-bottom: 1px solid $gray-400;

  // .kip-title {
  //   color: $primary;
  //   font-weight: 500;
  // }

  .kip-divider {
    font-weight: 300;
  }

  &__exit {
    font-weight: 500;
    color: $danger;
    letter-spacing: 1.25px;
    height: $button-height;
    display: flex;
    align-items: center;

    &--warning {
      --bs-btn-hover-bg: #{$kip-orange-200};
      --bs-btn-hover-color: #{$warning};

      color: $warning;
      background: $kip-orange-100;

      @media (hover: hover) {
        &:hover {
          background-color: $kip-orange-200;
          color: $warning;
        }
      }
    }

    &--danger {
      --bs-btn-hover-bg: #{$kip-red-200};
      --bs-btn-hover-color: #{$danger};

      color: $danger;
      background: $kip-red-100;

      @media (hover: hover) {
        &:hover {
          background-color: $kip-red-200;
          color: $danger;
        }
      }
    }
  }

  &__close {
    // @TODO: To be removed
    font-weight: 500;
    color: $danger;
    height: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    padding: 0 1em;
    letter-spacing: 1.25px;

    @media (hover: hover) {
      &:hover {
        background-color: map.get($alert-backgrounds, danger);
        color: $danger;
      }
    }
  }
}

.kip-student {
  // @TODO: Temporary until tutor header format is designed/decided
  .kip-header {
    display: flex;
    align-items: center;
    height: 4em;
    border-bottom: 0;
  }
}

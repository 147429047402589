.kip-matching-layout {
  .kip-container {
    position: relative;

    .kip-connectors {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      .kip-connector {
        stroke: $primary;
        stroke-width: 2px;
      }
    }

    .kip-column {
      list-style: none;
      min-width: 40%;
      z-index: 2;
      position: inherit;

      .kip-option {
        cursor: default;
        background-color: $gray-300;
        border: 1px solid $gray-500;
        font-size: 1.2rem;
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;

        &.kip-active {
          color: $white;
          background-color: $primary;
          border: 1px solid $primary;
        }

        &.kip-correct {
          @extend .alert-success;
        }

        &.kip-incorrect {
          @extend .alert-danger;
        }
      }

      &.kip-spacer {
        min-width: 20%;
      }
    }
  }
}

@use "sass:math";
@use "sass:color";

$max-image-viewport-width: 935;
$letters: 20;

@mixin kip-input-width {
  @for $i from 1 through $letters {
    .kip-input-#{$i}:not([style]) {
      min-width: calc(6 * 0.65em);
      width: calc(#{$i} * 0.65em);
      text-align: center;
    }
  }
}

.kip-question {
  .kip-layout {
    width: 100%;
    font-size: 2rem;

    img {
      max-height: 50vh;
      max-width: 50%;
    }

    @include image-widths;

    table img {
      max-width: 90%;
      max-height: inherit;
    }

    .kip-option-text img {
      max-width: 100%;
      max-height: inherit;
    }

    .kip-question-text {
      user-select: none;
    }

    .kip-input {
      font-size: 2rem;
      text-align: center;
      padding: 0;
      box-shadow: inset 0 0.5px 4px rgb(96 97 112 / 32%);

      &.form-control[readonly] {
        background-color: $white !important;
      }
    }

    .kip-option-image {
      max-width: 100%;
    }

    .kip-option-sound {
      cursor: pointer;
      color: $dark;
      margin-left: 0.5rem;

      &:hover {
        color: $primary;
      }

      @include theme {
        color: theme-get("primary");
        padding: 1.25em 1em 1.25em 0.75em;
        border-radius: 20px;
        margin-left: 0;
        margin-right: -1em;

        &:hover {
          color: theme-get("primary");
        }
      }
    }

    // removes any standard sizing for input boxes when they are inside a katex block
    // input boxes can be part of fractions, subscripts and superscripts
    // the positioning of these are different

    // makes the input boxes size based on katex styles

    .katex input {
      font-size: inherit;
      width: initial;
      line-height: initial;
    }

    .kip-bring-to-front {
      position: relative;
      z-index: 20 !important;
    }

    // Fixes not being able to click past the table row parent
    .katex .vlist-r {
      display: unset;
    }

    /* stylelint-disable no-invalid-position-at-import-rule */
    // Disabled rule to allow input width mixin and nesting.
    // Note that this file is used across multiple projects.

    @import "./question-layouts/generic-layout";
    @import "./question-layouts/multiple-choice-layout";
    @import "./question-layouts/sorting-layout";
    @import "./question-layouts/matching-layout";
    @import "./question-layouts/digital-clock-layout";
    @import "./question-layouts/analogue-clock-layout";
    @import "./question-layouts/drill-layout";
    @import "./question-layouts/spelling-layout";
    @import "./question-layouts/word-picker-layout";
    @import "./question-layouts/fill-in-the-blank-layout";

    /* stylelint-enable no-invalid-position-at-import-rule */
  }

  .kip-buttons {
    z-index: 20;

    .kip-submit {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 1.25px;
      border-radius: 8px;
      height: 3.5em;
      width: 6em;
      border: 0;
      background: $primary;

      @include theme {
        background: theme-get("primary");
      }

      &:hover {
        color: $white;
        background: color.adjust($primary, $lightness: -10%);


        @include theme {
          background: color.adjust(theme-get("primary"), $lightness: -10%);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .kip-layout {
      font-size: 1.4rem;

      .kip-input {
        font-size: 1.4rem;
      }
    }
  }
}

.region-uk {
  .image-au {
    display: none;
  }

  .image-us {
    display: none;
  }

  .image-nz {
    display: none;
  }

  .image-sa {
    display: none;
  }
}

.region-au {
  .image-uk {
    display: none;
  }

  .image-us {
    display: none;
  }

  .image-nz {
    display: none;
  }

  .image-sa {
    display: none;
  }
}

.region-sa {
  .image-au {
    display: none;
  }

  .image-us {
    display: none;
  }

  .image-nz {
    display: none;
  }

  .image-uk {
    display: none;
  }
}

.region-nz {
  .image-au {
    display: none;
  }

  .image-us {
    display: none;
  }

  .image-uk {
    display: none;
  }

  .image-sa {
    display: none;
  }
}

.region-us {
  .image-au {
    display: none;
  }

  .image-nz {
    display: none;
  }

  .image-uk {
    display: none;
  }

  .image-sa {
    display: none;
  }
}

// Turn radio buttons into buttons

.kip-question .kip-layout .kip-multiple-choice-layout {
  .kip-label {
    backface-visibility: hidden; // Experimental graphics fix
    color: $primary;
    background-color: $white;
    width: auto;
    height: 100%;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-shadow: none;
    padding: 1em;
    cursor: pointer;
    transition: background-color 0.15s;
    border-radius: 8px;
    outline: 1px solid #ddd;

    @include theme {
      color: theme-get("primary");
    }

    &:hover {
      background-color: $aurora-primary-100;

      @include theme {
        background-color: theme-get("primary-100");
      }
    }
  }

  // Selected item in Student view
    
  input:checked+.kip-label:not(.valid-choice):not(.invalid-choice) {
    color: $primary;
    background-color: $aurora-primary-200;
    outline: 1px solid $aurora-primary;

    @include theme {
      color: theme-get("primary");
      background-color: theme-get("primary-200");
      outline: 1px solid theme-get("primary");
      border: 0;
    }
  }
  
  // Student's answer in Tutor panel

  input:checked + .kip-label.valid-choice {
    outline: 3px solid $success;

  }

  input:checked + .kip-label.invalid-choice {
    outline: 3px solid $danger;
  }


  .valid-choice {
    outline: 0;
    color: #155724;
    background-color: #d4edda;
    pointer-events: none;
    
    .kip-option-sound {
      color: #155724;
    }
  }
  
  .invalid-choice {
    outline: 0;
    color: #a03535;
    background-color: #fae4d8;
    pointer-events: none;

    .kip-option-sound {
      color: #a03535;
    }
  }

  .custom-control.custom-radio {
    white-space: nowrap;
  }

  .custom-control {
    height: 100%;
    width: 100%;
  }

  // Override custom-control class
  .custom-radio,
  .custom-checkbox {
    padding-left: unset;

    .custom-control-label,
    .form-check-input {
      &::before,
      &::after {
        display: none; // Note, this is hiding a fake radio button, does not affect accessibility
      }
    }
  }

  .kip-option {
    padding: 0 0.25rem;
    margin-bottom: 0.5em;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .kip-input {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .row {
    align-items: flex-start;
    justify-content: center;
  }

  div[class*="col-"] {
    width: auto;
    flex: 0;
  }
}

.kip-success-tutor-answer {
  display: inline-block;
  color: #177060;
  background-color: #d3f8df;
  border-radius: 4px;
  padding: 0 0.25rem;
  font-weight: bold;
  margin-right: 0.25em;
}

.kip-generic-input {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: floralwhite;
  padding: 2px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.kip-generic-choice {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: yellow;
  padding: 2px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.kip-generic-image {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: silver;
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.kip-generic-clock {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: gray;
  width: 30px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.kip-tutor-toggle svg {
  height: 30px;
  width: 30px;
}

.kip-option,
.kip-option-selected {
  &.cdk-drag-preview {
    color: $dark;
    letter-spacing: 0.05em;
    font-family: Rubik, sans-serif;

    &:not(:has(img)) {
      background-color: $white;
      box-shadow: $box-shadow-btn;
    }

    .image-height-10 {
      height: 10vh;
    }
  }
}
